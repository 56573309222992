import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { hasCapability } from 'helpers/user';
import LoggedLayout from "../layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import EmpoweredSelector from "components/EmpoweredSelector";
import ThSortable from "components/ThSortable";

let axiosCancelToken = null;

const PaginatorWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

let searchTimeout = null;

export default function Competitions() {
	let [competitions, setCompetitions] = useState([]);
	let [search, setSearch] = useState("");
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('date_from');
	let [page, setPage] = useState(undefined);
	let [modality, setModality] = useState({
		value: '',
		label: ''
	});
	let [category, setCategory] = useState({
		value: '',
		label: ''
	});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const loadData = async () => {
			await axios.get('api/competitions/list', {
				params: {
					page: page,
					search: search,
					sort: sortField,
					direction: sortDirection,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setCompetitions(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		loadData();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [page, search, sortDirection, sortField]);

	const loadModalities = async (input, callback) => {
		await axios.get('/api/modalities/list', {
			params: {
				search: input
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: el.name,
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadCategories = async (input, callback) => {
		await axios.get('/api/categories/list', {
			params: {
				search: input,
				modality_id: modality.value ?? ''
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: 
						<div>
							<div>{el.name}</div>
							<small><i>{el.modality?.name}</i></small>
						</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const setSearchTimeout = (value) => {
		clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
		}, 500);
	}

	const sortTableClick = (field) => {
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>Competiciones</h1>
				<NavLink to="/competitions/new" className="btn btn-sm btn-danger text-white ms-auto">Añadir <i className="bi bi-plus"></i></NavLink>
			</div>
			<div className="page-content">
				<div className="row mb-3">
					<div className="col-md-4">
						{ hasCapability('modalities') &&
							<EmpoweredSelector
								load={(input, callback) => loadModalities(input, callback)}
								onChange={(value, text) => setModality({value: value, label: text})}
								value={modality.value}
								label={modality.label}
								placeholder="Modalidades"
								timeout={500}
							/>
						}
					</div>
					<div className="col-md-4">
						{ hasCapability('categories') &&
							<EmpoweredSelector
								load={(input, callback) => loadCategories(input, callback)}
								onChange={(value, text) => setCategory({value: value, label: text})}
								value={category.value}
								label={category.label}
								placeholder="Categorías"
								timeout={500}
							/>
						}
					</div>
					<div className="col-md-4">
						<input type="text" className="form-control form-control-sm" placeholder="Buscar..." onChange={(e) => setSearchTimeout(e.target.value)} />
					</div>
				</div>

				<div className="table-responsive">
					<table className="table table-sm table-hover table-bordered table-sortable">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'code'} onClick={() => sortTableClick('code')}>Código</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'category.name'} onClick={() => sortTableClick('category.name')}>Categoría</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'date_from'} onClick={() => sortTableClick('date_from')}>Comienza</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'date_to'} onClick={() => sortTableClick('date_to')}>Finaliza</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'matches.count'} onClick={() => sortTableClick('matches.count')}>Partidos</ThSortable>
								<th style={{width: '20px'}}></th>
							</tr>
						</thead>
						<tbody>
							{ competitions.data?.length > 0 &&
								competitions.data?.map((el, idx) => {
									return ( 
										<tr key={idx}>
											<td>{el.code}</td>
											<td>{el.name}</td>
											<td>{el.category.name ?? ''}</td>
											<td>{moment(el.date_from).format('DD-MM-YYYY')}</td>
											<td>{moment(el.date_to).format('DD-MM-YYYY')}</td>
											<td className="text-center">
												{ hasCapability('competitions') ?
													<NavLink to={'/matches?competition_id=' + el.id} className="text-danger">{el.matches_count}</NavLink>
													:
													el.matches_count
												}

											</td>
											<td>
												{ hasCapability('competitions') &&
													<NavLink to={'/competitions/edit/' + el.id} className="text-danger"><i className="bi bi-pencil"></i></NavLink>
												}
												{ hasCapability('competitions-statistics-upload') &&
													<NavLink to={'/competitions/statistics-upload/' + el.id} className="text-danger"><i className="bi bi-upload"></i></NavLink>
												}
											</td>
										</tr>
									);
								})
							}
							{ !competitions.data?.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }
						</tbody>
					</table>	
				</div>

				<PaginatorWrapper>
					<Paginator
						min={1}
						current={competitions?.current_page}
						max={competitions?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</PaginatorWrapper>
			</div>
		</LoggedLayout>
	);
}


