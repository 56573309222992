import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import useStore from "store";
import LoggedLayout from "../layouts/LoggedLayout";

const Table = styled.table`
	margin: 0;

	th {
		font-size: 11px;
		font-weight: 500;
		border-top: 0;

		&:nth-child(2) {
			width: 20px;
			max-width: 20px;
		}
	}
`;

const Tr = styled.tr`
	td {
		padding: 0;
		position: relative;

		input {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			border: none;
			padding: 10px 5px;
			min-height: auto;
			border-radius: 0;

			&.with-errors {
				background: var(--bs-red);
				color: white;
			}

			&[type=number] {
				text-align: right;
			}
		}

		&:nth-child(1) {
			width: 40%;
		}

		&:nth-child(2) {
			input {
				padding: 0;
			}
		}

		&:nth-child(3),
		&:nth-child(4) {
			width: 20%;
		}

		&:last-of-type {
			width: 5%;
			background: var(--bs-red);
			text-align: center;
			cursor: pointer;
			
			i {
				color: white;
			}
		}
	}
`;

let axiosCancelToken = null;

export default function Categories() {
    const user = useStore(state => state.user);
	let [reload, setReload] = useState(null);
	let [modalities, setModalities] = useState({});
	let [categories, setCategories] = useState([]);
	let [errors, setErrors] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const loadModalities = async () => {
			await axios.get('api/modalities/list', {
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setModalities(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		loadModalities();

		const loadData = async () => {
			await axios.get('api/categories/list', {
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setCategories(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		loadData();
		
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [reload]);

	const addCategory = (modality_id) => {
		let newCategories = [...categories, {id: uuidv4(), name: '', modality_id: parseInt(modality_id)}];
		setCategories(newCategories);
	}

	const updateCategory = (id, name) => {
		let newCategories = [...categories];
		let idx = newCategories.findIndex((el) => el.id === id);
		newCategories[idx].name = name;
		setCategories(newCategories);
	}

	const updateColor = (id, color) => {
		let newCategories = [...categories];
		let idx = newCategories.findIndex((el) => el.id === id);
		newCategories[idx].color = color;
		setCategories(newCategories);
	}

	const updatePrice = (type, id, price) => {
		let newCategories = [...categories];
		let idx = newCategories.findIndex((el) => el.id === id);
		newCategories[idx][type + '_price'] = price;
		setCategories(newCategories);
	}

	const removeCategory = (id) => {
		let newCategories = [...categories];
		newCategories.splice(newCategories.findIndex((el) => el.id === id), 1);
		setCategories(newCategories);
	}

	const saveData = (modality_id) => {
		setErrors({});

		let toSave = categories.filter((el) => el.modality_id === parseInt(modality_id)).map((el) => {
			return {
				id: el.id, 
				name: el.name, 
				modality_id: modality_id,
				referee_price: el.referee_price,
				scorer_price: el.scorer_price,
				color: el.color
			};
		});

		axios.post('api/categories/save', {
			modality_id: modality_id,
			categories: toSave 
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			setReload(Math.random());
			toast.success('Datos guardados');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	// Prepare columns
	let modalities_columns = [];
	for(let i in modalities) {
		let modality = modalities[i];
		if ( !user.capabilities.includes('modality-*') && !user.capabilities.includes('modality-' + modality.id) ) continue;

		let modality_inner_content = [];
		categories.filter((el) => el.modality_id === modality.id).forEach((el, idx) => {
			let nameError = errors['categories.' + idx + '.name'] ? errors['categories.' + idx + '.name'][0] : null;
			let refereePriceError = errors['categories.' + idx + '.referee_price'] ? errors['categories.' + idx + '.referee_price'][0] : null;
			let scorerPriceError = errors['categories.' + idx + '.scorer_price'] ? errors['categories.' + idx + '.scorer_price'][0] : null;
			let colorError = errors['categories.' + idx + '.color'] ? errors['categories.' + idx + '.color'][0] : null;
			modality_inner_content.push(
				<Tr key={idx}>
					<td><input type="text" className={'form-control form-control-sm ' + ( nameError ? 'with-errors' : '')} value={el.name} onChange={(e) => updateCategory(el.id, e.target.value)} /></td>
					<td><input type="color" className={' ' + ( colorError ? 'with-errors' : '')} value={el.color ?? ''} onChange={(e) => updateColor(el.id, e.target.value)} /></td>
					<td><input type="number" className={'form-control form-control-sm ' + ( refereePriceError ? 'with-errors' : '')} value={el.referee_price ?? ''} onChange={(e) => updatePrice('referee', el.id, e.target.value)} /></td>
					<td><input type="number" className={'form-control form-control-sm ' + ( scorerPriceError ? 'with-errors' : '')} value={el.scorer_price ?? ''} onChange={(e) => updatePrice('scorer', el.id, e.target.value)} /></td>
					<td onClick={() => removeCategory(el.id)}>
						<i className="bi bi-trash"></i>
					</td>
				</Tr>
			);
		})
		modalities_columns.push(
			<div key={'modality-'+modality.id} className="col-md-6">
				<div className="card">
					<div className="card-header d-flex">
						<h4 className="card-title mb-0">{ modality.name }</h4>
						<button className="btn btn-sm text-danger ms-auto" onClick={(e) => addCategory(modality.id)}>Añadir <i className="bi bi-plus"></i></button>
					</div>
					<div className="card-body p-2">
						<Table className="table table-sm table-bordered">
							<thead>
								<tr>
									<th>Categoría</th>
									<th>Color</th>
									<th>Precio Árbitro</th>
									<th>Precio Anotador</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{modality_inner_content}
							</tbody>
						</Table>
					</div>
					<div className="card-footer d-flex">
						<button className="btn btn-sm btn-danger text-white ms-auto" onClick={() => saveData(modality.id)}>Guardar</button>
					</div>
				</div>
			</div>
		);
	}


	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>Categorías</h1>
			</div>
			<div className="page-content">
				<div className="row">
					{ modalities_columns }
				</div>
			</div>
		</LoggedLayout>
	);
}


