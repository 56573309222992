import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import LoggedLayout from "../layouts/LoggedLayout";
import EmpoweredSelector from "components/EmpoweredSelector";

let axiosCancelToken = null;

export default function ModalityForm() {
	const navigate = useNavigate();
	const params = useParams();
	const [data, setData] = useState({
		name: '',
		coordinator_id: null,
		coordinator_name: ''
	});
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		if ( params.id ) {
			const loadData = async () => {
				await axios.get('api/modalities/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					setData({...response.data, coordinator_name: response.data.coordinator?.name});
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});	
			};
			loadData();
		}

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [params.id]);

	const loadCoordinators = (input, callback) => {
        // Get coordinators
		axios.get('/api/users/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {value: el.id, label: el.full_name};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = () => {
		setErrors({});

		axios.post('api/modalities/save' + (params.id ? '/' + params.id : ''), {
			name: data.name,
			coordinator_id: data.coordinator_id 
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			navigate(-1);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const deleteModality = () => {
		const c = window.confirm('¿Seguro que quieres eliminar esta modalidad?');
		if ( !c ) return false;

		axios.delete('api/modalities/delete/' + data.id, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Modalidad eliminada');
			navigate(-1);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al eliminar');
		});	
	}

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>{ params.id ? 'Editar modalidad' : 'Nueva modalidad' }</h1>
				<button className="btn btn-sm btn-secondary ms-auto" onClick={() => navigate(-1)}>Volver</button>
			</div>
			<div className="page-content d-flex justify-content-center">
				<div className="col-md-12">
					<div className="card">
						<div className="card-body">
							<form className="row">
								<div className="col-md-6 mb-2">
									<label htmlFor="name">Nombre</label>
									<input type="text" id="name" className="form-control form-control-sm" onChange={(e) => setData({...data, name: e.target.value})} value={data.name} />
									{ errors.name &&
										<div className="invalid-feedback d-block">{ errors.name[0] }</div>
									}
								</div>
								<div className=" col-md-6 mb-2">
									<label htmlFor="coordinator">Coordinador</label>
									<EmpoweredSelector
										load={(input, callback) => loadCoordinators(input, callback)}
										onChange={(value, text) => setData({...data, coordinator_id: value, coordinator_name: text})}
										timeout={500}
										value={data.coordinator_id}
										label={data.coordinator_name}
									/>
									{ errors.coordinator_id &&
										<div className="invalid-feedback d-block">{ errors.coordinator_id[0] }</div>
									}
								</div>
							</form>
						</div>
						<div className="card-footer d-flex">
							{ data.id &&
								<button className="btn btn-sm btn-link text-secondary" onClick={(e) => deleteModality()}>Eliminar</button>
							}
							<button className="btn btn-sm btn-danger text-white ms-auto" onClick={(e) => saveData()}>Guardar</button>
						</div>
					</div>
				</div>
			</div>
		</LoggedLayout>
	);
}


