import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';
import LoggedLayout from "../layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import AccountingTableMatches from "./AccountingTableMatches";

let axiosCancelToken = null;

const PaginatorWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const Tr = styled.tr`

	td {
		font-weight: ${props => props.opened ? '500' : 'normal'};

		&:last-of-type {
			background: ${props => props.opened ? 'var(--bs-dark)' : 'var(--bs-red)'};
			color: white !important;
			cursor: pointer;
			border-right: 0;
		}
	}
`;

const TrDetails = styled.tr`
	display: ${props => props.opened ? 'table-row' : 'none'};

	& > td {
		border-bottom: 2px solid var(--bs-red);
		padding: 0;
	}

	&:hover > * {
		--bs-table-accent-bg: unset !important;
	}
`;

let searchTimeout = null;

export default function Accounting() {
	let [data, setData] = useState([]);
	let [search, setSearch] = useState("");
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('id');
	let [page, setPage] = useState(undefined);
	let [detailsOpened, setDetailsOpened] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const loadData = async () => {
			await axios.get('api/accounting/list', {
				params: {
					page: page,
					search: search,
					sort: sortField,
					direction: sortDirection
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setData(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		loadData();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [page, search, sortDirection, sortField]);

	const setSearchTimeout = (value) => {
		clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
		}, 500);
	}

	const sortTableClick = (field) => {
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (id) => {
		let newDetailsOpened = [...detailsOpened];

		if ( newDetailsOpened.includes(id) ) {
			let idx = newDetailsOpened.findIndex((el) => el === id);
			newDetailsOpened.splice(idx, 1);
		} else {
			newDetailsOpened.push(id);
		}

		setDetailsOpened(newDetailsOpened);
	}

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>Contabilidad</h1>
			</div>
			<div className="page-content">
				<div className="row mb-3">
					<div className="col-md-4">
						
					</div>
					<div className="col-md-4">
						
					</div>
					<div className="col-md-4">
						<input type="text" className="form-control form-control-sm" placeholder="Buscar..." onChange={(e) => setSearchTimeout(e.target.value)} />
					</div>
				</div>

				<div className="table-responsive">
					<table className="table table-sm table-hover table-bordered table-sortable">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'id'} onClick={() => sortTableClick('id')}>Nombre</ThSortable>
								<th style={{width: '100px'}}>Cargo</th>
								<th style={{width: '20px'}}></th>
							</tr>
						</thead>
						<tbody>
							{ data.data?.length > 0 &&
								data.data?.map((el, idx) => {
									return ( 
										<React.Fragment key={idx}>
											<Tr opened={detailsOpened.includes(el.id)}>
												<td>{el.full_name}</td>
												<td>{el.role_string}</td>
												<td onClick={() => toggleDetails(el.id)}>
													<i className={'bi bi-caret-' + (detailsOpened.includes(el.id) ? 'up' : 'down') + '-fill'}></i>
												</td>
											</Tr>
											<TrDetails opened={detailsOpened.includes(el.id)}>
												<td colSpan="100%">
													{ detailsOpened.includes(el.id) &&
														<AccountingTableMatches user={el} />
													}
												</td>
											</TrDetails>
										</React.Fragment>
									);
								})
							}
							{ !data.data?.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }
						</tbody>
					</table>	
				</div>

				<PaginatorWrapper>
					<Paginator
						min={1}
						current={data?.current_page}
						max={data?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</PaginatorWrapper>
			</div>
		</LoggedLayout>
	);
}


