import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios';
import { hasCapability } from 'helpers/user';
import LoggedLayout from "../layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import EmpoweredSelector from "components/EmpoweredSelector";
import ThSortable from "components/ThSortable";

let axiosCancelToken = null;

const PaginatorWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

let searchTimeout = null;

export default function Teams() {
	let [teams, setTeams] = useState([]);
	let [search, setSearch] = useState("");
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('name');
	let [category, setCategory] = useState({
		id: null,
		name: ''
	});
	let [modality, setModality] = useState({
		id: null,
		name: ''
	});
	let [page, setPage] = useState(undefined);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const loadData = async () => {
			await axios.get('api/teams/list', {
				params: {
					page: page,
					search: search,
					sort: sortField,
					direction: sortDirection,
					modality_id: modality.id ?? '',
					category_id: category.id ?? '',
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setTeams(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		loadData();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [page, search, sortDirection, sortField, category.id, modality.id]);

	const loadCategories = (input, callback) => {
        // Get coordinators
		axios.get('/api/categories/list', {
			params: {
				search: input
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: 
						<div>
							<div>{el.name}</div>
							<small><i>{el.modality?.name}</i></small>
						</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadModalities = (input, callback) => {
        // Get coordinators
		axios.get('/api/modalities/list', {
			params: {
				search: input
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: 
						<div>
							<div>{el.name}</div>
							<small><i>{el.modality?.name}</i></small>
						</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const setSearchTimeout = (value) => {
		clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
		}, 500);
	}

	const sortTableClick = (field) => {
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>Equipos</h1>
				<NavLink to="/teams/new" className="btn btn-sm btn-danger text-white ms-auto">Añadir <i className="bi bi-plus"></i></NavLink>
			</div>
			<div className="page-content">
				<div className="row mb-3">
					<div className="col-md-4">
						{ hasCapability('modality-*') &&
							<EmpoweredSelector
								load={(input, callback) => loadModalities(input, callback)}
								onChange={(value, text) => setModality({id: value, name: text})}
								timeout={500}
								value={modality.id}
								label={modality.name}
								placeholder="Modalidades"
							/>
						}
					</div>
					<div className="col-md-4">
						<EmpoweredSelector
								load={(input, callback) => loadCategories(input, callback)}
								onChange={(value, text) => setCategory({id: value, name: text})}
								timeout={500}
								value={category.id}
								label={category.name}
								placeholder="Categorías"
							/>
					</div>
					<div className="col-md-4">
						<input type="text" className="form-control form-control-sm" placeholder="Buscar..." onChange={(e) => setSearchTimeout(e.target.value)} />
					</div>
				</div>

				<div className="table-responsive">
					<table className="table table-sm table-hover table-bordered table-sortable">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Equipo</ThSortable>
								{ hasCapability('modality-*') &&
									<ThSortable direction={sortDirection} active={sortField === 'category.modality'} onClick={() => sortTableClick('category.modality')}>Modalidad</ThSortable>	
								}
								<ThSortable direction={sortDirection} active={sortField === 'category.name'} onClick={() => sortTableClick('category.name')}>Categoría</ThSortable>
								<th style={{width: '20px'}}></th>
							</tr>
						</thead>
						<tbody>
							{ teams.data?.length > 0 &&
								teams.data?.map((el, idx) => {
									return ( 
										<React.Fragment key={idx}>
											<tr>
												<td>
													{ el.abbreviation &&
														<span className="badge bg-light text-dark fw-normal">{el.abbreviation}</span>
													}&nbsp;
													{el.name}
												</td>
												{ hasCapability('modality-*') &&
													<td>{el.category?.modality?.name}</td>
												}
												<td>{el.category?.name}</td>
												<td>
													<NavLink to={'/teams/edit/' + el.id} className="text-danger"><i className="bi bi-pencil"></i></NavLink>
												</td>
											</tr>
										</React.Fragment>
									);
								})
							}
							{ !teams.data?.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }
						</tbody>
					</table>	
				</div>

				<PaginatorWrapper>
					<Paginator
						min={1}
						current={teams?.current_page}
						max={teams?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</PaginatorWrapper>
			</div>
		</LoggedLayout>
	);
}


