import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import LoggedLayout from "../layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import EmpoweredSelector from "components/EmpoweredSelector";
import ThSortable from "components/ThSortable";
import { Popover } from 'bootstrap/dist/js/bootstrap';
import { downloadFile } from "helpers/generic";

let axiosCancelToken = null;

const PaginatorWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const Tr = styled.tr`
	&.postponed {
		background: #80808014; // gray
	}

	&.finished {
		background: #0080001c; // green
	}

	&.passed {
		background: #ffa50045; // orange
	}
`;

let searchTimeout = null;

export default function Matches() {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const competition_id = searchParams.get('competition_id');

	let [competition, setCompetition] = useState({});
	let [matches, setMatches] = useState([]);
	let [search, setSearch] = useState("");
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('date');
	let [page, setPage] = useState(undefined);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		// Not continue if not id
		if ( competition_id === null ) return;

		const loadCompetition = async () => {
			await axios.get('api/competitions/get/' + competition_id, {
				params: {
					page: page,
					search: search,
					sort: sortField,
					direction: sortDirection,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setCompetition(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
				navigate(-1);
			});	
		};
		loadCompetition();

		const loadData = async () => {
			await axios.get('api/matches/list/' + competition_id, {
				params: {
					page: page,
					search: search,
					sort: sortField,
					direction: sortDirection,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setMatches(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		loadData();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [competition_id, page, search, sortDirection, sortField, navigate]);

	useEffect(() => {
		let popoverToggles = document.querySelectorAll('[data-toggle="popover"]');
		let popoverList = [];
		if ( popoverToggles.length ) { 
			popoverToggles.forEach((el) => {
				popoverList.push(new Popover(el, {
					container: 'body',
					placement: 'auto',
					trigger: 'hover',
					html: true
				}));
			});
		}

		return function cleanup() {
			popoverList.forEach((el) => el.dispose());
        }
	}, [matches]);

	const loadCompetitions = async (input, callback) => {
		await axios.get('/api/competitions/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: el.name,
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const setSearchTimeout = (value) => {
		clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
		}, 500);
	}

	const sortTableClick = (field) => {
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const changeCompetitionEvent = (value, text) => {
		setCompetition({id: null});
		setMatches([]);
		setSearchParams(value ? {'competition_id': value} : {});
	}

	const exportExcel = async () => {
		await axios.get('/api/matches/export-excel/' + competition_id, {
			responseType: 'blob',
			params: {
				page: page,
				search: search,
				sort: sortField,
				direction: sortDirection,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			downloadFile(response.data, 'partidos.xlsx')
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>Partidos</h1>
				{ competition.id &&
					<NavLink to={'/matches/new/?competition_id=' + competition.id} className="btn btn-sm btn-danger text-white ms-auto">Añadir <i className="bi bi-plus"></i></NavLink>
				}
			</div>
			<div className="page-content">
				<div className="row mb-3">
					<div className="col-md-4">
						{ competition.id &&
							<h3 className="mb-0">{competition.name}</h3>
						}
					</div>
					<div className="col-md-3">
						<EmpoweredSelector
							load={(input, callback) => loadCompetitions(input, callback)}
							onChange={(value, text) => changeCompetitionEvent(value, text)}
							placeholder="Seleccionar competición"
							timeout={500}
							value={competition.id}
							label={competition.name}
						/>
					</div>
					<div className="col-md-3">
						{ competition.id &&
							<input type="text" className="form-control form-control-sm" placeholder="Buscar..." onChange={(e) => setSearchTimeout(e.target.value)} />
						}
					</div>
					<div className="col-md-2 text-end">
						<button className="btn btn-sm btn-secondary text-white ms-auto" onClick={exportExcel}>Exportar</button>
					</div>
				</div>

				{ competition.id && 
					<React.Fragment>
						<div className="table-responsive">
							<table className="table table-sm table-hover table-bordered table-sortable">
								<thead>
									<tr>
										<th style={{width: '20px'}}>#</th>
										<ThSortable style={{width: '130px'}} direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
										<ThSortable style={{width: '90px'}} direction={sortDirection} active={sortField === 'postponed_at'} onClick={() => sortTableClick('postponed_at')}>Pospuesto</ThSortable>
										<ThSortable direction={sortDirection} active={sortField === 'field.name'} onClick={() => sortTableClick('field.name')}>Campo</ThSortable>
										<ThSortable direction={sortDirection} active={sortField === 'localteam.name'} onClick={() => sortTableClick('localteam.name')}>Equipo Local</ThSortable>
										<ThSortable direction={sortDirection} active={sortField === 'awayteam.name'} onClick={() => sortTableClick('awayteam.name')}>Equipo Visitante</ThSortable>
										<th style={{width: '100px'}}>Puntuación</th>
										<th style={{width: '20px'}}></th>
									</tr>
								</thead>
								<tbody>
									{ matches.data?.length > 0 &&
										matches.data?.map((el, idx) => {
											let className = '';
											if ( el.postponed_at ) className = 'postponed';
											if ( (el.localteam_score !== null || el.awayteam_score !== null) && className !== 'postponed' ) className = 'finished';
											if ( !className ) {
												let now = moment();
												if ( now.isAfter(el.date) ) className = 'passed';
											}

											return ( 
												<Tr key={idx} className={className}>
													<td><span className="badge bg-light text-dark fw-normal">{el.code}</span></td>
													<td>{moment(el.date).format('DD-MM-YYYY')} <small>{moment(el.date).format('HH:mm')}</small></td>
													<td>
														{ el.postponed_at &&
															<div className="text-center">
																<span 
																	className="postponed-badge badge bg-secondary"
																	title="" 
																	data-toggle="popover"
																	data-bs-content={'Partido aplazado para el <b>' + moment(el.postponed_at).format('DD-MM-YYYY') + '</b> a las <b>' + moment(el.postponed_at).format('HH:mm') + '</b>' + (el.postponed_reason ? '<div><hr />'+el.postponed_reason+'</div>' : '')}
																>
																	Pospuesto
																</span>
															</div>
														}

														{ el.parent &&
															<div className="text-center">
																<span 
																	className="text-warning"
																	title="" 
																	data-toggle="popover"
																	data-bs-content={'Partido creado por aplazo de otro partido con fecha del <b>' + moment(el.parent.date).format('DD-MM-YYYY') + '</b> a las <b>' + moment(el.parent.date).format('HH:mm')}
																>
																	<i className="bi bi-arrow-right"></i>
																</span>
															</div>
														}
													</td>
													<td>{el.field?.name}</td>
													<td>{el.localteam?.name}</td>
													<td>{el.awayteam?.name}</td>
													<td className="text-center">{el.localteam_score} - {el.awayteam_score}</td>
													<td>
														<NavLink to={'/matches/edit/' + el.id} className="text-danger"><i className="bi bi-pencil"></i></NavLink>
													</td>
												</Tr>
											);
										})
									}
									{ !matches.data?.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }
								</tbody>
							</table>	
						</div>

						<PaginatorWrapper>
							<Paginator
								min={1}
								current={matches?.current_page}
								max={matches?.last_page}
								changeCallback={(page) => setPage(page)}
							/>
						</PaginatorWrapper>
					</React.Fragment>
				}
			</div>
		</LoggedLayout>
	);
}


