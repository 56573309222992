import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { toast } from 'react-toastify';

let axiosCancelToken = null;

const TableMatches = styled.table`
	width: 100%;
	font-size: 13px;

	thead {

		th {
			font-weight: normal;
			border-bottom: 1px solid var(--bs-gray-300);
			padding: 5px;

			&:nth-child(1) {
				width: 200px;
			}

			&:nth-child(2) {
				width: 100px;
			}

			&:nth-child(5) {
				width: 150px;
				text-align: right;
			}

			&:nth-child(6) {
				width: 20px;
			}
		}
	}

	tbody {

		tr {
			border-bottom: 1px solid var(--bs-gray-300);

			td {
				padding: 5px;

				&:nth-child(5) {
					text-align: right;

					small {
						font-size: 9px;
					}
				}

				input[type=checkbox] {
					width: 15px;
					height: 15px;
					cursor: pointer;
				}
			}
		}
	}
`;

export default function AccountingTableMatches(props) {
	const navigate = useNavigate();

	let [data, setData] = useState([]);
	let [checked, setChecked] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const getData = async () => {
			return await axios.get('api/accounting/get-unpaid-matches/' + props.user.id, {
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setData(response.data);
				setChecked(response.data.map((el) => el.id));
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getData();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const toggleChecked = (id) => {
		let newChecked = [...checked];
		if ( newChecked.includes(id) ) {
			let idx = newChecked.findIndex((el) => el === id);
			newChecked.splice(idx, 1);
		} else {
			newChecked.push(id);
		}
		setChecked(newChecked);
	}

	const generateReceipt = () => {
		axios.post('api/accounting/create-receipt', {
			user_id: props.user.id,
			matches: checked
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			navigate('/receipts?open=' + response.data.receipt.id);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	let total = 0;

	let fields_with_complements = [];

	return (
		<TableMatches>
			<thead>
				<tr>
					<th>Fecha</th>
					<th>Competición</th>
					<th>Equipos</th>
					<th>Campo</th>
					<th>Importe</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{ data.map((el, idx) => {
					// Price
					let price = 0;
					if ( props.user.role === 'referee' ) price = el.competition.referee_price;
					if ( props.user.role === 'scorer' ) price = el.competition.scorer_price;
					price = parseFloat(price);

					// Complement
						let complement = 0;

						// Check if was paid for other match in same day
						let complement_idx = el.field_id + '_' + moment(el.date).format('YYYY-MM-DD');
						let complement_paid_before_on_same_day = fields_with_complements.includes(complement_idx);

						// Calc
						if ( props.user.custom_fields !== undefined && 
								props.user.custom_fields.fields !== undefined &&
									props.user.custom_fields.fields[el.field_id] !== undefined &&
										!complement_paid_before_on_same_day 
						) complement = props.user.custom_fields.fields[el.field_id];
						complement = parseFloat(complement);
						if ( isNaN(complement) || !checked.includes(el.id) ) complement = 0;

						// Set to prevent re-pay
						if ( complement !== 0 ) fields_with_complements.push(complement_idx);

						// Sum
						price += complement;

					// Total
					if ( checked.includes(el.id) ) total += price;

					return (
						<tr key={idx}>
							<td>
								<div>{moment(el.date).format('DD-MM-YYYY')}</div>
								<div>a las {moment(el.date).format('HH:mm')}</div>
							</td>
							<td>
								{el.competition.name}
								<div><small><i>{el.competition.category?.modality?.name} {el.competition.category?.name}</i></small></div>
							</td>
							<td>
								<div>{ el.localteam?.name }</div>
								<div>{ el.awayteam?.name }</div>
							</td>
							<td>{el.field?.name}</td>
							<td>
								<div>{price.toFixed(2)} €</div>
								{ complement > 0 &&
									<div><small>(complemento {complement}€ aplicado)</small></div>
								}
							</td>
							<td className="text-center"><input type="checkbox" checked={checked.includes(el.id)} onChange={() => toggleChecked(el.id)} /></td>
						</tr>
					);
				}) }
			</tbody>
			<tfoot>
				<tr>
					<td colSpan="100%" className="p-2 text-end">
						<div className="fw-bold mb-2">TOTAL: {total.toFixed(2)}€</div>
						<button className="btn btn-sm btn-danger text-white" onClick={() => generateReceipt()}>Generar recibo</button>
					</td>
				</tr>
			</tfoot>
		</TableMatches>
	);
}