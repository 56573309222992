import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import LoggedLayout from "../layouts/LoggedLayout";
import EmpoweredSelector from "components/EmpoweredSelector";

const Team = styled.div`
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	border-radius: 10px;
	background: white;
	margin: 5px;
	border: 1px solid var(--bs-gray-300);

	&:hover {
		cursor: pointer;

		&:after {
			content: "×";
			color: var(--bs-red);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(255,255,255,0.8);
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 30px;
		}
	}
`;

let axiosCancelToken = null;

export default function CompetitionForm() {
	const navigate = useNavigate();
	const params = useParams();
	const [data, setData] = useState({
		code: '',
		name: '',
		category_id: null,
		category_name: null,
		teams: []
	});
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const loadData = async () => {
				await axios.get('api/competitions/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					setData({
						...response.data, 
						category_name: 
							<div>
								<div>{response.data.category?.name}</div>
								<small><i>{response.data.category?.modality.name}</i></small>
							</div>,
						teams: response.data.teams.map((el) => {
							return {
								id: el.id,
								name: el.name
							};
						})
					});
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});	
			};
			loadData();
		}
	}, [params.id]);

	const loadCategories = (input, callback) => {
        // Get coordinators
		axios.get('/api/categories/list', {
			params: {
				search: input
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: 
						<div>
							<div>{el.name}</div>
							<small><i>{el.modality?.name}</i></small>
						</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadTeams = (input, callback) => {
		axios.get('/api/teams/list', {
			params: {
				search: input,
				no_paginate: true,
				category_id: data.category_id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: 
						<div>
							<div>{el.name}</div>
							<small><i>{el.modality?.name}</i></small>
						</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const addTeam = (id, name) => {
		let newTeams = [...data.teams];

		let idx = newTeams.findIndex((el) => el.id === id);
		if ( idx !== -1 ) return;

		newTeams.push({
			id: id,
			name: name
		});
		setData({...data, teams: newTeams});
	}

	const removeTeam = (id) => {
		let newTeams = [...data.teams];
		let idx = newTeams.findIndex((el) => el.id === id);
		newTeams.splice(idx, 1);
		setData({...data, teams: newTeams});
	}

	const saveData = async () => {
		setErrors({});

		await axios.post('api/competitions/save' + (params.id ? '/' + params.id : ''), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			navigate(-1);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const deleteCompetition = () => {
		const c = window.confirm('¿Seguro que quieres eliminar esta competición?');
		if ( !c ) return false;

		axios.delete('api/competitions/delete/' + data.id, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Competición eliminada');
			navigate(-1);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al eliminar');
		});	
	}
 
	const selectCategory = (id, text) => {
		axios.get('/api/categories/get/' + id, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let referee_price = response.data.referee_price ?? null;
			let scorer_price = response.data.scorer_price ?? null;

			setData({...data, referee_price: referee_price, scorer_price: scorer_price, category_id: id, category_name: text, teams: []})
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>{ params.id ? 'Editar competición' : 'Nueva competición' }</h1>
				<div className="ms-auto">
					{ params.id &&
						<button className="btn btn-sm btn-warning me-2" onClick={() => navigate('/matches?competition_id=' + params.id)}>Ver/añadir partidos</button>
					}
					<button className="btn btn-sm btn-secondary" onClick={() => navigate(-1)}>Volver</button>
				</div>
			</div>
			<div className="page-content d-flex justify-content-center">
				<div className="col-md-12">
					<div className="card">
						<div className="card-body">
							<form className="row">
								<div className="col-md-4 mb-2">
									<label htmlFor="code">Código</label>
									<input type="text" id="code" className="form-control form-control-sm" onChange={(e) => setData({...data, code: e.target.value})} value={data.code ?? ''} />
									{ errors.code &&
										<div className="invalid-feedback d-block">{ errors.code[0] }</div>
									}
								</div>
								<div className="col-md-4 mb-2">
									<label htmlFor="name">Nombre</label>
									<input type="text" id="name" className="form-control form-control-sm" onChange={(e) => setData({...data, name: e.target.value})} value={data.name ?? ''} />
									{ errors.name &&
										<div className="invalid-feedback d-block">{ errors.name[0] }</div>
									}
								</div>
								<div className="col-md-4 mb-2">
									<label htmlFor="category_id">Categoría</label>
									<EmpoweredSelector
										load={(input, callback) => loadCategories(input, callback)}
										onChange={(value, text) => selectCategory(value, text)}
										timeout={500}
										value={data.category_id}
										label={data.category_name}
										disabled={data.id ? true : false}
									/>
									{ errors.category_id &&
										<div className="invalid-feedback d-block">{ errors.category_id[0] }</div>
									}
								</div>
								<div className="col-md-4 mb-2">
									<label htmlFor="date_from">Comienza el</label>
									<input type="date" id="date_from" className="form-control form-control-sm" onChange={(e) => setData({...data, date_from: e.target.value})} value={data.date_from ?? ''} />
									{ errors.date_from &&
										<div className="invalid-feedback d-block">{ errors.date_from[0] }</div>
									}
								</div>
								<div className="col-md-4 mb-2">
									<label htmlFor="date_to">Finaliza el</label>
									<input type="date" id="date_to" className="form-control form-control-sm" onChange={(e) => setData({...data, date_to: e.target.value})} value={data.date_to ?? ''} />
									{ errors.date_to &&
										<div className="invalid-feedback d-block">{ errors.date_to[0] }</div>
									}
								</div>
								<div className="col-md-4 mb-2">
									<label htmlFor="referee_price">Precio árbitro</label>
									<input type="number" id="referee_price" step="0.01" className="form-control form-control-sm" onChange={(e) => setData({...data, referee_price: e.target.value})} value={data.referee_price ?? ''} />
									{ errors.referee_price &&
										<div className="invalid-feedback d-block">{ errors.referee_price[0] }</div>
									}
								</div>
								<div className="col-md-4 mb-2">
									<label htmlFor="scorer_price">Precio anotador</label>
									<input type="number" id="scorer_price" step="0.01" className="form-control form-control-sm" onChange={(e) => setData({...data, scorer_price: e.target.value})} value={data.scorer_price ?? ''} />
									{ errors.scorer_price &&
										<div className="invalid-feedback d-block">{ errors.scorer_price[0] }</div>
									}
								</div>

								<div className="col-md-12 mt-2">
									<div className="border rounded bg-light p-2">
										<div className="row justify-content-end">
											<div className="col-md-3">
												<EmpoweredSelector
													load={(input, callback) => loadTeams(input, callback)}
													onChange={(value, text) => addTeam(value, text)}
													timeout={500}
													label="Equipos"
													placeholder="Equipos"
													value={null}
													disabled={data.category_id ? false : true}
													except={data.teams.map((el) => el.id)}
												/>
											</div>
										</div>
										<div className="teams-wrapper">
											{ data.teams.map((el) => {
												return  <Team key={el.id} onClick={() => removeTeam(el.id)}>
															{ el.name }
														</Team>;
											}) }
										</div>
									</div>
								</div>
							</form>
						</div>
						<div className="card-footer d-flex">
							{ data.id &&
								<button className="btn btn-sm btn-link text-secondary" onClick={(e) => deleteCompetition()}>Eliminar</button>
							}
							<button className="btn btn-sm btn-danger text-white ms-auto" onClick={(e) => saveData()}>Guardar</button>
						</div>
					</div>
				</div>
			</div>
		</LoggedLayout>
	);
}


