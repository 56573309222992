import { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getUser, setUser } from 'helpers/user';

const ToggleAvailableButton = styled.div`
	padding-top: 20px;
	padding-left: 20px;

	.form-check-input {
		transform: scale(1.5);
		margin-right: 15px;
	}
`;

let axiosCancelToken = null;

export default function ScorerRefererToggleAvailableSwitch() {

	const [checked, setChecked] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const getAvailability = () => {
			axios.get('api/scorer-referer-matches/get-availability', {
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setChecked(response.data.status);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getAvailability();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const toggleAvailability = (status) => {
		axios.post('api/scorer-referer-matches/toggle-availability', {
			status: status
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			setUser({...getUser(), ...response.data.user});
			setChecked(status);
			toast.success('Se ha actualizado tu disponibilidad');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		});	
	}
 
	return (
		<ToggleAvailableButton>
			<div className="form-check form-switch">
				<input className="form-check-input" type="checkbox" id="available_week_btn" checked={checked} onChange={(e) => toggleAvailability(!checked)} />
				<label className="form-check-label" htmlFor="available_week_btn">Esta semana estoy disponible</label>
			</div>
		</ToggleAvailableButton>
	);
}


