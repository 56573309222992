import LoggedLayout from "../layouts/LoggedLayout";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { hasCapability } from 'helpers/user';
import ScorerRefererToggleAvailableSwitch from './ScorerRefererToggleAvailableSwitch';

import AnotadoresIcon from 'assets/icons/anotadores.svg';
import UsuariosIcon from 'assets/icons/usuarios.svg';
import ArbitrosIcon from 'assets/icons/arbitros.svg';
import ModalidadesIcon from 'assets/icons/modalidad.svg';
import CamposIcon from 'assets/icons/campos.svg';
import EquiposIcon from 'assets/icons/equipos.svg';
import CompeticionesIcon from 'assets/icons/competiciones.svg';
import RecibosIcon from 'assets/icons/recibos.svg';
import OtroIcon from 'assets/icons/otro.svg';

const ButtonsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 20%);
  	padding: 30px 0;
  	user-select: none;

  	@media (max-width: 992px) {
		grid-template-columns: repeat(3, 33.33%);
  	}

  	@media (max-width: 600px) {
		grid-template-columns: repeat(2, 50%);
  	}
`;

const Button = styled.a`
	background: var(--bs-red);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 10px;
	border-radius: 15px;
	margin: 5px;
	color: white;
	text-decoration: none;
	text-transform: uppercase;
	text-align: center;
	font-size: 15px;
	line-height: 15px;

	&:hover {
		color: white;
		opacity: 0.9;
	}

	img {
		width: 30px;
		margin-bottom: 10px;
	}
`;

export default function Dashboard() {
	const navigate = useNavigate();

    const goTo = (e, to) => {
    	e.preventDefault();
    	navigate(to);
    }

	return (
		<LoggedLayout>
			{ hasCapability('scorer-referer-matches') &&
				<ScorerRefererToggleAvailableSwitch />
			}

			<ButtonsWrapper>
				{ hasCapability('users') &&
					<Button href="/" onClick={(e) => goTo(e, '/users')}>
						<img src={UsuariosIcon} alt="usuarios" />
						Usuarios
					</Button>
				}

				{ hasCapability('users-referee') &&
					<Button href="/" onClick={(e) => goTo(e, '/users')}>
						<img src={ArbitrosIcon} alt="árbitros" />
						Árbitros
					</Button>
				}

				{ hasCapability('users-scorer') &&
					<Button href="/" onClick={(e) => goTo(e, '/users')}>
						<img src={AnotadoresIcon} alt="anotadores" />
						Anotadores
					</Button>
				}

				{ hasCapability('modalities') &&
					<Button href="/" onClick={(e) => goTo(e, '/modalities')}>
						<img src={ModalidadesIcon} alt="modalidades" />
						Modalidades
					</Button>
				}

				{ hasCapability('categories') &&
					<Button href="/" onClick={(e) => goTo(e, '/categories')}>
						<img src="" alt="" />
						Categorías
					</Button>
				}

				{ hasCapability('fields') &&
					<Button href="/" onClick={(e) => goTo(e, '/fields')}>
						<img src={CamposIcon} alt="campos" />
						Campos
					</Button>
				}

				{ hasCapability('teams') &&
					<Button href="/" onClick={(e) => goTo(e, '/teams')}>
						<img src={EquiposIcon} alt="equipos" />
						Equipos
					</Button>
				}

				{ (hasCapability('competitions') || hasCapability('competitions-statistics-upload')) &&
					<Button href="/" onClick={(e) => goTo(e, '/competitions')}>
						<img src={CompeticionesIcon} alt="competiciones" />
						Competiciones
					</Button>
				}

				{ hasCapability('matches') &&
					<Button href="/" onClick={(e) => goTo(e, '/matches')}>
						<img src="" alt="" />
						Partidos
					</Button>
				}

				{ hasCapability('matches-referee') &&
					<Button href="/" onClick={(e) => goTo(e, '/referee-selection')}>
						<img src={ArbitrosIcon} alt="arbitros" />
						Selección árbitros
					</Button>
				}

				{ hasCapability('matches-monitor') &&
					<Button href="/" onClick={(e) => goTo(e, '/monitor-selection')}>
						<img src={ArbitrosIcon} alt="monitores" />
						Selección monitores
					</Button>
				}

				{ hasCapability('matches-scorer') &&
					<Button href="/" onClick={(e) => goTo(e, '/scorer-selection')}>
						<img src={AnotadoresIcon} alt="anotadores" />
						Selección anotadores
					</Button>
				}

				{ hasCapability('accounting') &&
					<Button href="/" onClick={(e) => goTo(e, '/accounting')}>
						<img src="" alt="" />
						Contabilidad
					</Button>
				}

				{ hasCapability('receipts') &&
					<Button href="/" onClick={(e) => goTo(e, '/receipts')}>
						<img src={RecibosIcon} alt="recibos" />
						{ hasCapability('scorer-referer-matches') ? 'Pagados' : 'Recibos' }
					</Button>
				}

				{ hasCapability('scorer-referer-matches') &&
					<Button href="/" onClick={(e) => goTo(e, '/scorer-referer-matches?type=next')}>
						<img src="" alt="" />
						Partidos próximos
					</Button>
				}

				{ hasCapability('scorer-referer-matches') &&
					<Button href="/" onClick={(e) => goTo(e, '/scorer-referer-matches?type=notpaid')}>
						<img src="" alt="" />
						Partidos sin pagar
					</Button>
				}

				{ hasCapability('generate-scripts') &&
					<Button href="/" onClick={(e) => goTo(e, '/generate-scripts')}>
						<img src={OtroIcon} alt="otro" />
						Shortcodes
					</Button>
				}
			</ButtonsWrapper>
		</LoggedLayout>
	);
}


