import React from 'react';
import axios from 'axios';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { getUser, setUser } from 'helpers/user';

import Login from "pages/Login";
import Dashboard from "pages/Dashboard";
import Modalities from "pages/Modalities";
import ModalityForm from "pages/ModalityForm";
import Categories from "pages/Categories";
import Fields from "pages/Fields";
import FieldForm from "pages/FieldForm";
import Teams from "pages/Teams";
import TeamForm from "pages/TeamForm";
import Users from "pages/Users";
import UserForm from "pages/UserForm";
import Competitions from "pages/Competitions";
import CompetitionForm from "pages/CompetitionForm";
import CompetitionStatisticsUploadForm from "pages/CompetitionStatisticsUploadForm";
import Matches from "pages/Matches";
import MatchForm from "pages/MatchForm";
import RefereeScorerSelection from "pages/RefereeScorerSelection";
import Accounting from "pages/Accounting";
import Receipts from "pages/Receipts";
import ScorerRefererMatches from "pages/ScorerRefererMatches";
import GenerateScripts from "pages/GenerateScripts";

function App() {
    const location = useLocation();

    // Axios global
    axios.defaults.withCredentials = true;
    axios.defaults.baseURL = '/';
    
    // Axios interceptors
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        // Check unauthorized error
        if ( error.response && error.response.status === 401 ) {
            setUser(null, true);
        }

        // Default
        return Promise.reject(error);
    });
    
    return (
        <React.Fragment>
            { (!getUser() && location.pathname !== '/login') &&
                <Navigate to="/login" />
            }

            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="login" element={<Login />} />
                
                <Route path="/modalities" element={<Modalities />} />
                <Route path="/modalities/new" element={<ModalityForm />} />
                <Route path="/modalities/edit/:id" element={<ModalityForm />} />

                <Route path="/categories" element={<Categories />} />

                <Route path="/fields" element={<Fields />} />
                <Route path="/fields/new" element={<FieldForm />} />
                <Route path="/fields/edit/:id" element={<FieldForm />} />

                <Route path="/teams" element={<Teams />} />
                <Route path="/teams/new" element={<TeamForm />} />
                <Route path="/teams/edit/:id" element={<TeamForm />} />

                <Route path="/users" element={<Users />} />
                <Route path="/users/new" element={<UserForm />} />
                <Route path="/users/edit/:id" element={<UserForm />} />

                <Route path="/competitions" element={<Competitions />} />
                <Route path="/competitions/new" element={<CompetitionForm />} />
                <Route path="/competitions/edit/:id" element={<CompetitionForm />} />
                <Route path="/competitions/statistics-upload/:id" element={<CompetitionStatisticsUploadForm />} />

                <Route path="/matches" element={<Matches />} />
                <Route path="/matches/new" element={<MatchForm />} />
                <Route path="/matches/edit/:id" element={<MatchForm />} />

                <Route path="/referee-selection" element={<RefereeScorerSelection type="referee" />} />
                <Route path="/scorer-selection" element={<RefereeScorerSelection type="scorer" />} />
                <Route path="/monitor-selection" element={<RefereeScorerSelection type="monitor" />} />

                <Route path="/accounting" element={<Accounting />} />
                
                <Route path="/receipts" element={<Receipts />} />

                <Route path="/scorer-referer-matches" element={<ScorerRefererMatches />} />

                <Route path="/generate-scripts" element={<GenerateScripts />} />
            </Routes>
            <ToastContainer
                position="bottom-right"
                autoClose={2000}
            />
        </React.Fragment>
    );
}

export default App;