import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import LoggedLayout from "../layouts/LoggedLayout";
import EmpoweredSelector from "components/EmpoweredSelector";

let axiosCancelToken = null;

const allowedFieldsTemplate = {
	'calendar': ['hideToolbar', 'modality', 'category', 'year', 'month', 'lastWeek'],
	'list': ['hideToolbar', 'modality', 'category', 'year', 'month', 'lastWeek'],
	'results': ['competition', 'category', 'modality']
};

export default function GenerateScripts() {
	const [type, setType] = useState('calendar');
	const [hideToolbar, setHideToolbar] = useState(false);
	const [year, setYear] = useState(null);
	const [month, setMonth] = useState(null);
	const [lastWeek, setLastWeek] = useState(null);

	const [allowedFields, setAllowedFields] = useState([]);

	const [modality, _setModality] = useState({id: null, name: null});
	const modalityRef = useRef(modality); // Fix to access on loadCategories function
	const setModality = (data) => {
		modalityRef.current = data;
		_setModality(data);
	}

	const [category, _setCategory] = useState({id: null, name: null});
	const categoryRef = useRef(category); // Fix to access on loadCategories function
	const setCategory = (data) => {
		categoryRef.current = data;
		_setCategory(data);
	}

	const [competition, setCompetition] = useState({id: null, name: null});
	const [code, setCode] = useState(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		setAllowedFields(allowedFieldsTemplate[type]);
	}, [type]);

	const loadModalities = (input, callback) => {
		axios.get('/api/modalities/list', {
			params: {
				search: input,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	};

	const loadCategories = (input, callback) => {
		axios.get('/api/categories/list', {
			params: {
				search: input,
				modality_id: modalityRef.current.id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label:  <div>
								<div>{el.name}</div>
								<small><i>{el.modality?.name}</i></small>
							</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	};

	const loadCompetitions = (input, callback) => {
		axios.get('/api/competitions/list', {
			params: {
				search: input,
				modality_id: modalityRef.current.id,
				category_id: categoryRef.current.id,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label:  <div>
								<div>{el.name}</div>
								<small><i>{el.name.name}</i></small>
							</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	};

	const loadYears = (input, callback) => {
		axios.get('/api/competitions/get-years', {
			params: {},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const generate = () => {
		let hostname = window.location.hostname;
		let port = window.location.port;
		let protocol = window.location.protocol;

		let url = protocol + '//' + hostname + (port && port !== 80 ? ':' + port : '') + '/calendar.js';

		let qs = {};
		if ( type ) qs.type = type;
		if ( hideToolbar ) qs.hide_toolbar = hideToolbar;
		if ( year && !lastWeek ) qs.year = year;
		if ( month && !lastWeek ) qs.month = month;
		if ( modality.id ) qs.modality_id = modality.id;
		if ( category.id ) qs.category_id = category.id;
		if ( competition.id ) qs.competition_id = competition.id;
		if ( lastWeek ) qs.last_week = lastWeek ? 1 : 0;
		qs = Object.keys(qs).map(key => key + '=' + qs[key]).join('&');

		let code = '<script id="fmbs_script_'+uuidv4()+'" src="'+url+'?'+qs+'"></script>';
		setCode(code);
	}

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>Shortcodes</h1>
			</div>
			<div className="page-content">
				<div className="row">
					<div className="col-md-3 mb-2">
						<label>Tipo</label>
						<select className="form-control form-control-sm" onChange={(e) => setType(e.target.value)} value={type}>
							<option value="calendar">Calendario</option>
							<option value="list">Listado</option>
							<option value="results">Tabla de resultados</option>
						</select>
					</div>
					<div className="col-md-3 mb-2">
						<label>Ocultar barra superior</label>
						<select className="form-control form-control-sm" onChange={(e) => setHideToolbar(e.target.value)} value={hideToolbar} disabled={allowedFields.indexOf('hideToolbar') === -1}>
							<option value={false}>No</option>
							<option value={true}>Si</option>
						</select>
					</div>
					<div className="col-md-3 mb-2">
						<label>Modalidad</label>
						<EmpoweredSelector
							load={(input, callback) => loadModalities(input, callback)}
							onChange={(value, text) => setModality({id: value, name: text})}
							timeout={500}
							value={modality.id}
							label={modality.name}
							disabled={allowedFields.indexOf('modality') === -1}
						/>
					</div>
					<div className="col-md-3 mb-2">
						<label>Categoría</label>
						<EmpoweredSelector
							load={(input, callback) => loadCategories(input, callback)}
							onChange={(value, text) => setCategory({id: value, name: text})}
							timeout={500}
							value={category.id}
							label={category.name}
							disabled={allowedFields.indexOf('category') === -1 ? true : false}
						/>
					</div>
					<div className="col-md-3 mb-2">
						<label>Competicion</label>
						<EmpoweredSelector
							load={(input, callback) => loadCompetitions(input, callback)}
							onChange={(value, text) => setCompetition({id: value, name: text})}
							timeout={500}
							value={competition.id}
							label={competition.name}
							disabled={allowedFields.indexOf('competition') === -1 ? true : false}
						/>
					</div>
					<div className="col-md-3 mb-2">
						<label>Año</label>
						<EmpoweredSelector
							load={(input, callback) => loadYears(input, callback)}
							onChange={(value, text) => setYear(value)}
							timeout={500}
							value={year}
							label={year}
							disabled={lastWeek || allowedFields.indexOf('year') === -1 ? true : false}
						/>
					</div>
					<div className="col-md-3 mb-2">
						<label>Mes</label>
						<select className="form-control form-control-sm" onChange={(e) => setMonth(e.target.value)} value={month ?? ''} disabled={lastWeek || allowedFields.indexOf('month') === -1 ? true : false}>
							{ [...Array(13).keys()].map((i) => {
								return <option key={i} value={i > 0 ? i : ''}>{i > 0 ? i : ''}</option>;
							}) }
						</select>
					</div>
					<div className="col-md-3 mb-2">
						<label>Mostrar última semana</label>
						<div className="form-check form-switch">
						  	<input className="form-check-input" type="checkbox" checked={lastWeek ?? false} onChange={(e) => setLastWeek(e.target.checked)} disabled={allowedFields.indexOf('lastWeek') === -1} />
						</div>
					</div>
					<div className="col-md-12 mb-2 text-end">
						<button className="btn btn-sm btn-danger text-white" onClick={() => generate()}>Generar</button>
					</div>
					<div className="col-md-12">
						<code>{code}</code>
					</div>
				</div>
			</div>
		</LoggedLayout>
	);
}


