import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import LoggedLayout from "../layouts/LoggedLayout";
import moment from "moment";

const Table = styled.table`
	td {
		position: relative;

		input {
		
			&[type=file] {
				visibility: hidden;
				width: 0;
				height: 0;
				position: absolute;
			}
		}
	}
	
`;

let axiosCancelToken = null;

export default function CompetitionForm() {
	const navigate = useNavigate();
	const params = useParams();
	const [data, setData] = useState({
		code: '',
		name: '',
		category_name: null,
		teams: []
	});
	const [errors, setErrors] = useState({});
	const [forceReload, setForceReload] = useState(false);

	const inputRefs = useRef({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const loadData = async () => {
				await axios.get('api/competitions/get/' + params.id, {
					params: {
						append_csv: true
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					setData({
						...response.data, 
						category_name: 
							<div>
								<div>{response.data.category?.name}</div>
								<small><i>{response.data.category?.modality.name}</i></small>
							</div>,
						teams: response.data.teams.map((el) => {
							return {
								id: el.id,
								name: el.name,
								csv_batting: el.csv_batting ? true : false,
								csv_fielding: el.csv_fielding ? true : false,
								csv_pitching: el.csv_pitching ? true : false
							};
						})
					});
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});	
			};
			loadData();
		}
	}, [params.id, forceReload]);

	const openFileInput = (e, key) => {
		e.preventDefault();
		inputRefs.current[key].click();
	}

	const saveFile = async (e, team_id, type) => {
		if (e) e.preventDefault();

		let file = null;
		if (e && e.target && e.target.files) file = e.target.files[0];
		if (!file) return;

		e.target.value = null; // Reset input

		let formData = new FormData();
		formData.append('file', file);
		formData.append('team_id', team_id);
		formData.append('type', type);

		await axios.post('api/competitions/upload-statistics/' + params.id, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos actualizados');
			setForceReload(Math.random());
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const removeFile = async (e, team_id, type) => {
		e.preventDefault();

		await axios.post('api/competitions/remove-statistics/' + params.id, {
			team_id: team_id,
			type: type
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos actualizados');
			setForceReload(Math.random());
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>Subir estadísticas</h1>
				<div className="ms-auto">
					<button className="btn btn-sm btn-secondary" onClick={() => navigate(-1)}>Volver</button>
				</div>
			</div>
			<div className="page-content d-flex justify-content-center">
				<div className="col-md-12">
					<div className="card">
						<div className="card-body">
							<form className="row">
								<div className="col-md-4 mb-2">
									<label htmlFor="code">Código</label>
									<input type="text" id="code" className="form-control form-control-sm" onChange={(e) => null} value={data.code ?? ''} readOnly={true} />
									{ errors.code &&
										<div className="invalid-feedback d-block">{ errors.code[0] }</div>
									}
								</div>
								<div className="col-md-8 mb-2">
									<label htmlFor="name">Nombre</label>
									<input type="text" id="name" className="form-control form-control-sm" onChange={(e) => null} value={data.name ?? ''} readOnly={true} />
									{ errors.name &&
										<div className="invalid-feedback d-block">{ errors.name[0] }</div>
									}
								</div>
								<div className="col-md-12 mt-2">
									<Table className="table table-bordered">
										<thead>
											<tr>
												<th>Equipo</th>
												<th>Batting</th>
												<th>Fielding</th>
												<th>Pitching</th>
											</tr>
										</thead>
										<tbody>
											{ data.teams.map((el) => {
												let batting = el.id + '_batting';
												let fielding = el.id + '_fielding';
												let pitching = el.id + '_pitching';

												return (
													<tr key={el.id}>
														<td>{el.name}</td>
														<td>
															<input type="file" ref={(ref) => inputRefs.current[batting] = ref} onChange={(e) => saveFile(e, el.id, 'batting')} />
															
															{el.csv_batting ?
																<>
																	<div>{moment(el.csv_batting.created_at).format('DD-MM-YYYY HH:MM')}</div>
																	<button onClick={(e) => removeFile(e, el.id, 'batting')} className="btn btn-sm btn-danger p-0 px-1">
																		<i className="bi bi-x"></i>
																	</button>
																</>
																:
																<button onClick={(e) => openFileInput(e, batting)} className="btn btn-sm btn-light p-0 px-1">
																	<i className="bi bi-upload"></i>
																</button>
															}
														</td>
														<td>
															<input type="file" ref={(ref) => inputRefs.current[fielding] = ref} onChange={(e) => saveFile(e, el.id, 'fielding')} />
															
															{el.csv_fielding ?
																<>
																	<div>{moment(el.csv_fielding.created_at).format('DD-MM-YYYY HH:MM')}</div>
																	<button onClick={(e) => removeFile(e, el.id, 'fielding')} className="btn btn-sm btn-danger p-0 px-1">
																		<i className="bi bi-x"></i>
																	</button>
																</>
																:
																<button onClick={(e) => openFileInput(e, fielding)} className="btn btn-sm btn-light p-0 px-1">
																	<i className="bi bi-upload"></i>
																</button>
															}
														</td>
														<td>
															<input type="file" ref={(ref) => inputRefs.current[pitching] = ref} onChange={(e) => saveFile(e, el.id, 'pitching')} />
															
															{el.csv_pitching ?
																<>
																	<div>{moment(el.csv_pitching.created_at).format('DD-MM-YYYY HH:MM')}</div>
																	<button onClick={(e) => removeFile(e, el.id, 'pitching')} className="btn btn-sm btn-danger p-0 px-1">
																		<i className="bi bi-x"></i>
																	</button>
																</>
																:
																<button onClick={(e) => openFileInput(e, pitching)} className="btn btn-sm btn-light p-0 px-1">
																	<i className="bi bi-upload"></i>
																</button>
															}
														</td>
													</tr>
												)
											})}
										</tbody>
									</Table>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</LoggedLayout>
	);
}


