import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';

const Selector = styled.div`
	user-select: none;
`;

const Label = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	font-size: 14px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;

	&:hover:not(.disabled) {
		background: rgba(1,1,1, 0.02);
	}

	&::after {
		position: absolute;
		top: 50%;
		right: 10px;
	}

	&.disabled {
		opacity: 0.7;
		cursor: default !important;
	}

	i {
		&.dropdown-toggle-icon {
			display: flex;
			align-items: center;
			height: 100%;
			position: absolute;		
			top: 0;
			right: 8px;
			font-size: 10px;
			line-height: 0;

			&:hover {
				transform: scale(1.2);
			}
		}

		&.remove-icon {
			display: flex;
			align-items: center;
			height: 100%;
			position: absolute;		
			top: 0;
			right: 25px;
			color: var(--bs-red);
			font-size: 18px;
			line-height: 0;

			&:hover {
				transform: scale(1.2);
			}
		}
	}
`;

const Menu = styled.div`
	width: 100%;
	max-height: 300px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-top: 0;
	z-index: 20;
	overflow: hidden;

	&.show {
		display: flex;
		flex-direction: column;
	}

	.search-wrapper {
		padding: 0px 8px;
		margin-bottom: 8px;

		input {
			padding: 2px 6px;
			font-size: 13px;
			height: auto;
			min-height: auto;
			box-shadow: none;
		}
	}

	.results-wrapper {
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.dropdown-item {
		font-size: 14px;
		padding: 4px 14px;
		white-space: normal;

		&.header {
			opacity: 0.9;
			font-weight: bold;
			pointer-events: none;
			padding-left: 8px;
		}

		&.clickable {
			cursor: pointer;
		}

		&.loading {
			user-select: none;
			font-size: 13px;
			text-align: center;
		}
	}
`;

let timeout = null;

const EmpoweredSelector = (props) => {
	let selectorRef = useRef(null);
	let searchRef = useRef(null);

	let [forceReload, setForceReload] = useState(null);
	let [loading, setLoading] = useState(false);
	let [search, setSearch] = useState(null);
	let [data, setData] = useState([]);
	let [value, setValue] = useState(null);
	let [label, setLabel] = useState(null);
	let [opened, _setOpened] = useState(false);
	let openedRef = useRef(false);
	let setOpened = (status) => {
		openedRef.current = status;
		_setOpened(status);
	}

	// Fix to prevent load on re-render because props change every render on parent
	let propsLoad = useRef(props.load);
	let propsTimeout = useRef(props.timeout);

	useEffect(() => {
		setValue(props.value);
		setLabel(props.label ? props.label : (props.placeholder ?? null))
	}, [props.value, props.label, props.placeholder]);

	useEffect(() => {
		let cancel = false;

		if ( search === null ) return; // Prevents enter on first render
		
		setLoading(true);

		const loadData = async () => {
			await propsLoad.current(search, (data) => {
				setData(data);
				setLoading(false);
			});
		};
		
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			if ( cancel ) return;
			loadData();
		}, propsTimeout.current ?? 0);		

		return function cleanup() {
			cancel = true;
		}
	}, [search, propsLoad, propsTimeout, forceReload])

	const toggleDropdown = useCallback((status) => {
		if ( props.disabled ) return;

		setOpened(status);
		if ( status ) {
			setSearch(search ?? '');
			setForceReload(Math.random());
			setTimeout(() => {
				searchRef.current.focus();
			}, 0);
		} else {
			setData([]);
		}
	}, [search, searchRef, props.disabled]);

	useEffect(() => {
		const clickEventListener = (e) => {
			if ( selectorRef.current && !selectorRef.current.contains(e.target) && openedRef.current ) {
				toggleDropdown(false);
			}
		};
		const keyboardEventListener = (e) => {
			if ( e.keyCode === 27 && selectorRef.current && selectorRef.current.contains(e.target) ) {
				e.preventDefault();
				e.stopPropagation();
				toggleDropdown(false);
			}
		};
		window.addEventListener('click', clickEventListener);
		window.addEventListener('keydown', keyboardEventListener, true);

		return function cleanup() {
			window.removeEventListener('click', clickEventListener);
			window.removeEventListener('keydown', keyboardEventListener, true);
		}
	}, [selectorRef, toggleDropdown, openedRef]);

	const onFocusEvent = (e) => {
		if ( !opened && selectorRef.current.contains(document.activeElement) ) toggleDropdown(true);
	}

	const onKeyEvent = (e) => {
		if ( opened && e.keyCode === 9 ) toggleDropdown(false);
	}

	const onMouseEvent = (e) => {		
		// Prevent open when clicking remove-icon
		if ( e.target.classList.contains('remove-icon') ) {
			e.stopPropagation();
			e.preventDefault();
			return false;
		}

		opened = !opened;
		if ( !opened ) e.preventDefault();
		toggleDropdown(opened);
	}

	const select = (data) => {
		setValue(props.value ?? null);
		setLabel(props.label ?? props.placeholder);
		toggleDropdown(false);

		if ( props.onChange ) props.onChange(data.value, data.label);
	}

	const remove = (e) => {
		e.stopPropagation();
		if ( props.disabled ) return;
		setValue(null);
		setLabel(props.placeholder ?? null);
		toggleDropdown(false);
		if ( props.onChange ) props.onChange(null, null);
	}

	let items = [];
	if ( data.length ) {
		for(let idx in data) {
			let el = data[idx];
			if ( props.except && props.except.includes(el.value) ) continue;
			items.push(<div key={idx} className={'dropdown-item ' + (el.header ? 'header' : 'clickable')} onClick={() => el.header ? false : select(el)} value={el.value}>{el.label}</div>);
		}
	}
	if ( !items.length ) items = <div className="dropdown-item loading">No hay resultados</div>;

	return (
		<Selector ref={selectorRef} className="empowered-selector" tabIndex="0" onFocus={(e) => onFocusEvent(e)} onKeyDown={(e) => onKeyEvent(e)}>
			<div className={'dropdown ' + (opened ? 'show' : '')}>
				<Label className={'empowered-selector_label form-control form-control-sm ' + (props.disabled ? 'disabled' : '')} onMouseDown={(e) => onMouseEvent(e)}>
					{ label }
					<i className="bi bi-caret-down-fill dropdown-toggle-icon"></i>
					{ value &&
						<i className="bi bi-x remove-icon" onClick={(e) => remove(e)}></i>
					}					
				</Label>
				<Menu className={'empowered-selector_menu dropdown-menu ' + (loading ? 'loading ' : '') + (opened ? 'show ' : '')}>
					<div className="search-wrapper">
						<input ref={searchRef} type="text" placeholder="Buscar" className="form-control form-control-sm" onChange={(e) => setSearch(e.target.value)} />
					</div>
					<div className="results-wrapper">
						{ loading ?
							<div className="dropdown-item loading">cargando...</div>
							:
							items
						}
					</div>
				</Menu>
			</div>
		</Selector>
	);
}

export default EmpoweredSelector;