import useStore from "store";

export function hasCapability(capability) {
	let user = useStore.getState().user;
	return user?.capabilities?.includes(capability) ?? false;
}

export const getUser = () => {
	return useStore.getState().user;
}
 
export function setUser(data, reload = false) {
	useStore.getState().dispatch({
		type: 'setUser',
		data: data
	});

	if (reload) window.location.reload(); // Fix to reload when login or logout because this helpers cant rerender react
} 