import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios';
import LoggedLayout from "../layouts/LoggedLayout";

const Modality = styled.a`
	display: flex;
	width: 100%;
	height: 200px;
	text-decoration: none !important;
	color: inherit !important;
	cursor: pointer;

	.card {
		width: 100%;
		height: 100%;
		background: var(--bs-light);

		.card-body {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
	}

	&.add {
		i {
			display: flex;
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;
			font-size: 50px;
			color: var(--bs-gray-600)
		}
	}
`;

let axiosCancelToken = null;

export default function Modalities() {
	const navigate = useNavigate();

	let [modalities, setModalities] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const loadData = async () => {
			await axios.get('api/modalities/list', {
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setModalities(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		loadData();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>Modalidades</h1>
			</div>
			<div className="page-content">
				<div className="row">
					{ modalities?.map((el) => {
						return (
							<div key={el.id} className="col-md-3 mb-2">
								<Modality onClick={() => navigate('/modalities/edit/' + el.id)}>
									<div className="card">
										<div className="card-body">
											<h2>{el.name}</h2>
											<h4>{el.coordinator?.name ?? '-'}</h4>
										</div>
									</div>
								</Modality>
							</div>
						);
					}) }

					<div className="col-md-3">
						<Modality onClick={() => navigate('/modalities/new')} className="add">
							<div className="card">
								<div className="card-body">
									<i className="bi bi-plus-lg"></i>
								</div>
							</div>
						</Modality>
					</div>
				</div>
			</div>
		</LoggedLayout>
	);
}


