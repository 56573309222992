import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { useSearchParams } from "react-router-dom";
import { hasCapability } from 'helpers/user';
import LoggedLayout from "../layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import EmpoweredSelector from "components/EmpoweredSelector";
import ThSortable from "components/ThSortable";
import ReceiptDetails from "./ReceiptDetails";

let axiosCancelToken = null;

const PaginatorWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const Tr = styled.tr`

	td {
		font-weight: ${props => props.opened ? '500' : 'normal'};

		&:last-of-type {
			background: ${props => props.opened ? 'var(--bs-dark)' : 'var(--bs-red)'};
			color: white !important;
			cursor: pointer;
			border-right: 0;
		}

		&:nth-child(4) {
			text-align: right;
		}

		&:nth-child(5) {
			width: 150px;
		}
	}
`;

const TrDetails = styled.tr`
	display: ${props => props.opened ? 'table-row' : 'none'};

	& > td {
		border-bottom: 2px solid var(--bs-red);
		padding: 0;
	}

	&:hover > * {
		--bs-table-accent-bg: unset !important;
	}
`;

export default function Receipts() {
	let [searchParams] = useSearchParams();
	let open = searchParams.get('open');

	let [reload, setReload] = useState(null);
	let [receipts, setReceipts] = useState([]);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('id');
	let [page, setPage] = useState(undefined);
	let [status, setStatus] = useState(undefined);
	let [user, setUser] = useState({
		id: '',
		full_name: ''
	});
	let [detailsOpened, setDetailsOpened] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const loadData = async () => {
			await axios.get('api/receipts/list', {
				params: {
					page: page,
					sort: sortField,
					direction: sortDirection,
					user_id: user.id,
					status: status
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setReceipts(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		loadData();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [page, sortDirection, sortField, user, status, reload]);

	useEffect(() => {
		setDetailsOpened([parseInt(open)]);
	}, [open]);

	const sortTableClick = (field) => {
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (id) => {
		let newDetailsOpened = [...detailsOpened];

		if ( newDetailsOpened.includes(id) ) {
			let idx = newDetailsOpened.findIndex((el) => el === id);
			newDetailsOpened.splice(idx, 1);
		} else {
			newDetailsOpened.push(id);
		}

		setDetailsOpened(newDetailsOpened);
	}

	const loadUsers = async (input, callback) => {
		await axios.get('/api/receipts/get-users-list', {
			params: {
				search: input,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: el.full_name,
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>Recibos</h1>
			</div>
			<div className="page-content">
				<div className="row mb-3">
					<div className="col-md-4">
						
					</div>
					<div className="col-md-4">
						<select className="form-control form-control-sm" value={status} onChange={(e) => setStatus(e.target.value)}>
							<option value="">- Estado -</option>
							<option value="paid">Pagado</option>
							<option value="unpaid">No Pagado</option>
						</select>
					</div>
					<div className="col-md-4">
						{ hasCapability('accounting') &&
							<EmpoweredSelector
								load={(input, callback) => loadUsers(input, callback)}
								onChange={(value, text) => setUser({id: value, full_name: text})}
								value={user?.id}
								label={user?.full_name}
								placeholder="Usuarios"
								timeout={500}
							/>
						}
					</div>
				</div>

				<div className="table-responsive">
					<table className="table table-sm table-hover table-bordered table-sortable">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'full.number'} onClick={() => sortTableClick('full.number')}>Nº</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'user.fullName'} onClick={() => sortTableClick('user.fullName')}>Usuario</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
								<ThSortable style={{textAlign: 'right'}} direction={sortDirection} active={sortField === 'total'} onClick={() => sortTableClick('total')}>Total</ThSortable>
								<ThSortable style={{textAlign: 'center'}} direction={sortDirection} active={sortField === 'paid_at'} onClick={() => sortTableClick('paid_at')}>Pagado</ThSortable>
								<th style={{width: '20px'}}></th>
							</tr>
						</thead>
						<tbody>
							{ receipts.data?.length > 0 &&
								receipts.data?.map((el, idx) => {
									return ( 
										<React.Fragment key={idx}>
											<Tr opened={detailsOpened.includes(el.id)}>
												<td>{el.full_number}</td>
												<td>{el.user?.full_name}</td>
												<td>{moment(el.date).format('DD-MM-YYYY')}</td>
												<td>{el.total.toFixed(2)}€</td>
												<td className="text-center">
													{ el.paid_at ? 
														<span className="badge badge-sm bg-success">{moment(el.paid_at).format('DD-MM-YYYY')}</span> 
														: 
														<span className="badge badge-sm bg-danger">no</span>
													}
												</td>
												<td onClick={() => toggleDetails(el.id)}>
													<i className={'bi bi-caret-' + (detailsOpened.includes(el.id) ? 'up' : 'down') + '-fill'}></i>
												</td>
											</Tr>
											<TrDetails opened={detailsOpened.includes(el.id)}>
												<td colSpan="100%">
													{ detailsOpened.includes(el.id) &&
														<ReceiptDetails receipt={el} reloadParent={() => setReload(Math.random())} />
													}
												</td>
											</TrDetails>
										</React.Fragment>
									);
								})
							}
							{ !receipts.data?.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }
						</tbody>
					</table>	
				</div>

				<PaginatorWrapper>
					<Paginator
						min={1}
						current={receipts?.current_page}
						max={receipts?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</PaginatorWrapper>
			</div>
		</LoggedLayout>
	);
}


