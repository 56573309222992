import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { hasCapability } from 'helpers/user';
import LoggedLayout from "../layouts/LoggedLayout";
import EmpoweredSelector from "components/EmpoweredSelector";

let axiosCancelToken = null;

const TableFields = styled.table`
	tbody td {
		position: relative;

		input {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: none;
			text-align: right;
		}
	}
`;

export default function UserForm() {
	const navigate = useNavigate();
	const params = useParams();
	const [roles, _setRoles] = useState([]);
	const rolesRef = useRef(roles);
	const setRoles = (data) => {
		rolesRef.current = data;
		_setRoles(data);
	};
	const [fields, setFields] = useState([]);
	const [data, setData] = useState({
		name: '',
		lastname1: '',
		lastname2: '',
		vatnumber: '',
		address: '',
		email: '',
		phone: '',
		password: '',
		role: '',
		role_name: '',
		custom_fields: {}
	});
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const loadRoles = async () => {
			await axios.get('/api/users/roles', {
				params: {
					type: 'direct'
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				let single_role = hasCapability('users-scorer') ? 'scorer' : ( hasCapability('users-referee') ? 'referee' : null );
				let formatted = [];
				formatted = response.data.map((el) => {
					if ( single_role && single_role !== el.key ) return null;
					return {
						value: el.key, 
						label: el.name
					};
				}).filter((el) => el !== null);
				setRoles(formatted);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		loadRoles();
	}, []);

	useEffect(() => {
		// Get fields only if role needs
		if ( !['referee', 'scorer'].includes(data.role) ) return;

		const loadFields = async () => {
			await axios.get('/api/fields/list', {
				params: {
					no_paginate: true,
					sort: 'name',
					direction: 'asc'
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setFields(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		loadFields();
	}, [data.role]);

	useEffect(() => {
		if ( params.id && roles.length ) {
			const loadData = async () => {
				await axios.get('api/users/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					let role_name = roles.find((el) => el.value === response.data.role)?.label;
					setData({...response.data, role_name: role_name});
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});	
			};
			loadData();
		}
	}, [params.id, roles]);

	const saveData = async () => {
		setErrors({});

		await axios.post('api/users/save' + (params.id ? '/' + params.id : ''), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			navigate(-1);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const deleteUser = () => {
		const c = window.confirm('¿Seguro que quieres eliminar este usuario?');
		if ( !c ) return false;

		axios.delete('api/users/delete/' + data.id, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Equipo eliminada');
			navigate(-1);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al eliminar');
		});	
	}

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>{ params.id ? 'Editar usuario' : 'Nuevo usuario' }</h1>
				<button className="btn btn-sm btn-secondary ms-auto" onClick={() => navigate(-1)}>Volver</button>
			</div>
			<div className="page-content d-flex justify-content-center">
				<div className="col-md-12">
					<div className="card">
						<div className="card-body">
							<form className="row">
								<div className="col-md-3 mb-2">
									<label htmlFor="name">Nombre</label>
									<input type="text" id="name" className="form-control form-control-sm" onChange={(e) => setData({...data, name: e.target.value})} value={data.name ?? ''} />
									{ errors.name &&
										<div className="invalid-feedback d-block">{ errors.name[0] }</div>
									}
								</div>
								<div className="col-md-3 mb-2">
									<label htmlFor="lastname1">Apellido 1</label>
									<input type="text" id="lastname1" className="form-control form-control-sm" onChange={(e) => setData({...data, lastname1: e.target.value})} value={data.lastname1 ?? ''} />
									{ errors.lastname1 &&
										<div className="invalid-feedback d-block">{ errors.lastname1[0] }</div>
									}
								</div>
								<div className="col-md-3 mb-2">
									<label htmlFor="lastname2">Apellido 2</label>
									<input type="text" id="lastname2" className="form-control form-control-sm" onChange={(e) => setData({...data, lastname2: e.target.value})} value={data.lastname2 ?? ''} />
									{ errors.lastname2 &&
										<div className="invalid-feedback d-block">{ errors.lastname2[0] }</div>
									}
								</div>
								<div className="col-md-3 mb-2">
									<label htmlFor="vatnumber">DNI</label>
									<input type="text" id="vatnumber" className="form-control form-control-sm" onChange={(e) => setData({...data, vatnumber: e.target.value})} value={data.vatnumber ?? ''} />
									{ errors.vatnumber &&
										<div className="invalid-feedback d-block">{ errors.vatnumber[0] }</div>
									}
								</div>
								<div className="col-md-3 mb-2">
									<label htmlFor="address">Dirección</label>
									<input type="text" id="address" className="form-control form-control-sm" onChange={(e) => setData({...data, address: e.target.value})} value={data.address ?? ''} />
									{ errors.address &&
										<div className="invalid-feedback d-block">{ errors.address[0] }</div>
									}
								</div>
								<div className="col-md-3 mb-2">
									<label htmlFor="email">Email</label>
									<input type="text" id="email" className="form-control form-control-sm" onChange={(e) => setData({...data, email: e.target.value})} value={data.email ?? ''} />
									{ errors.email &&
										<div className="invalid-feedback d-block">{ errors.email[0] }</div>
									}
								</div>
								<div className="col-md-3 mb-2">
									<label htmlFor="phone">Teléfono</label>
									<input type="text" id="phone" className="form-control form-control-sm" onChange={(e) => setData({...data, phone: e.target.value})} value={data.phone ?? ''} />
									{ errors.phone &&
										<div className="invalid-feedback d-block">{ errors.phone[0] }</div>
									}
								</div>
								<div className="col-md-3 mb-2">
									<label htmlFor="role">Rol</label>
									<EmpoweredSelector
										load={(input, callback) => {
											let inputSanitized = input.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
											let filteredRoles = rolesRef.current.filter((el) => {
												let elSanitized = el.label.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
												if ( elSanitized.indexOf(inputSanitized) !== -1 ) return true;
												return false;
											});
											callback(filteredRoles); 
										}}

										onChange={(value, text) => setData({...data, role: value, role_name: text})}
										timeout={500}
										value={data.role}
										label={data.role_name}
										disabled={data.id && !data.is_role_editable}
									/>
									{ errors.role &&
										<div className="invalid-feedback d-block">{ errors.role[0] }</div>
									}
								</div>
								<div className="col-md-3 mb-2">
									<label htmlFor="password">Contraseña</label>
									<input type="text" id="password" className="form-control form-control-sm" placeholder="* * * * * *" onChange={(e) => setData({...data, password: e.target.value})} value={data.password ?? ''} />
									<small style={{fontSize: '10px', lineHeight: '10px'}} className="text-secondary">Dejar en blanco para no modificar</small>
									{ errors.password &&
										<div className="invalid-feedback d-block">{ errors.password[0] }</div>
									}
								</div>

								{ ['referee', 'scorer', 'trainer'].includes(data.role) &&
									<div className="col-md-12"><hr /></div>
								}

								{ ['referee', 'scorer', 'trainer'].includes(data.role) &&
									<div className="col-md-3 mb-2">
										<label htmlFor="custom_fields.level">Nivel</label>
										<input type="text" id="custom_fields.level" className="form-control form-control-sm" onChange={(e) => setData({...data, custom_fields: {...data.custom_fields, level: e.target.value}})} value={data.custom_fields?.level ?? ''} />
										{ errors['custom_fields.level'] &&
											<div className="invalid-feedback d-block">{ errors['custom_fields.level'][0] }</div>
										}
									</div>
								}

								{ ['referee', 'scorer'].includes(data.role) &&
									<React.Fragment>
										<div className="col-md-3 mb-2">
											<label htmlFor="custom_fields.license">Nº licencia</label>
											<input type="text" id="custom_fields.license" className="form-control form-control-sm" onChange={(e) => setData({...data, custom_fields: {...data.custom_fields, license: e.target.value}})} value={data.custom_fields?.license ?? ''} />
											{ errors['custom_fields.license'] &&
												<div className="invalid-feedback d-block">{ errors['custom_fields.license'][0] }</div>
											}
										</div>
										<div className="col-md-3 mb-2">
											<label htmlFor="custom_fields.irpf">Retenciones</label>
											<input type="number" id="custom_fields.irpf" className="form-control form-control-sm" onChange={(e) => setData({...data, custom_fields: {...data.custom_fields, irpf: e.target.value}})} value={data.custom_fields?.irpf ?? ''} />
											{ errors['custom_fields.irpf'] &&
												<div className="invalid-feedback d-block">{ errors['custom_fields.irpf'][0] }</div>
											}
										</div>
									</React.Fragment>
								}

								{ ['referee', 'scorer'].includes(data.role) &&
									<React.Fragment>
										<div className="col-md-12"><hr /></div>
										<div className="col-md-6 mb-2">
											<TableFields className="table table-sm table-bordered mb-0">
												<thead>
													<tr>
														<th>Campo</th>
														<th>Suplemento €</th>
													</tr>
												</thead>
												<tbody>
													{ fields?.map((el) => {
														return (
															<tr key={'field-' + el.id}>
																<td>{ el.name }</td>
																<td>
																	<input 
																		type="number" 
																		value={ data.custom_fields?.fields ? (data.custom_fields?.fields[el.id] ?? '') : '' } 
																		onChange={(e) => {
																			let custom_fields = data.custom_fields;
																			if ( !custom_fields ) custom_fields = {};
																			let fields = data.custom_fields?.fields;
																			if ( !fields ) fields = {};
																			fields[el.id] = e.target.value;
																			custom_fields.fields = fields;
																			setData({...data, custom_fields: custom_fields});
																		}} 
																	/>
																</td>
															</tr>
														);
													}) }
												</tbody>
											</TableFields>
										</div>
									</React.Fragment>
								}
							</form>
						</div>
						<div className="card-footer d-flex">
							{ data.id &&
								<button className="btn btn-sm btn-link text-secondary" onClick={(e) => deleteUser()}>Eliminar</button>
							}
							<button className="btn btn-sm btn-danger text-white ms-auto" onClick={(e) => saveData()}>Guardar</button>
						</div>
					</div>
				</div>
			</div>
		</LoggedLayout>
	);
}


