import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import LoggedLayout from "../layouts/LoggedLayout";
import EmpoweredSelector from "components/EmpoweredSelector";
import MatchFormPostponeModal from "./MatchFormPostponeModal";

let axiosCancelToken = null;

export default function MatchForm() {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const params = useParams();
	const competition_id = searchParams.get('competition_id');

	const [postponeModalOpened, setPostponeModalOpened] = useState(false);
	const [competition, setCompetition] = useState({});
	const [data, setData] = useState({
		competition_id: null,
		competition: {},
		number: null,
		date: null,
		field_id: null,
		field_name: '',
		localteam_id: null,
		localteam_name: '',
		awayteam_id: null,
		awayteam_name: '',
		localteam_score: '',
		awayteam_score: '',
		postponed_at: null,
		postponed_reason: ''
	});
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const loadData = async () => {
				await axios.get('api/matches/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					setData({
						...response.data, 
						field_name: response.data.field?.name,
						localteam_name: response.data.localteam?.name,
						awayteam_name: response.data.awayteam?.name,
					});
					setCompetition(response.data.competition);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});	
			};
			loadData();
		} else {
			const loadCompetition = async () => {
				await axios.get('api/competitions/get/' + competition_id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					setCompetition(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});	
			};
			loadCompetition();

			const getNextNumber = async () => {
				await axios.get('api/competitions/get-next-match-number/' + competition_id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					setData({...data, number: response.data.number});
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});	
			}
			getNextNumber();
		}
	}, [params.id, competition_id]);

	

	const loadFields = (input, callback) => {
		axios.get('/api/fields/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadTeams = (input, callback) => {
		axios.get('/api/teams/list', {
			params: {
				search: input,
				no_paginate: true,
				category_id: data.competition?.category_id ?? competition.category_id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: 
						<div>
							<div>{el.name}</div>
							<small><i>{el.modality?.name}</i></small>
						</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = async () => {
		setErrors({});

		let dataToSave = {...data, competition_id: competition.id};

		await axios.post('api/matches/save' + (data.id ? '/' + data.id : ''), dataToSave, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			navigate(-1);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const deleteMatch = () => {
		const c = window.confirm('¿Seguro que quieres eliminar este partido?');
		if ( !c ) return false;

		axios.delete('api/matches/delete/' + data.id, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Partido eliminado');
			navigate(-1);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al eliminar');
		});	
	}

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>{ params.match_id ? 'Editar partido' : 'Nuevo partido' }</h1>
				<div className="ms-auto">
					{ params.competition_id &&
						<button className="btn btn-sm btn-warning me-2" onClick={() => navigate('/competitions/edit/' + params.competition_id )}>Ver/editar competición</button>
					}
					<button className="btn btn-sm btn-secondary" onClick={() => navigate(-1)}>Volver</button>
				</div>
			</div>
			<div className="page-content d-flex justify-content-center">
				<div className="col-md-12">
					<div className="card">
						<div className="card-body">
							<form className="row">
								<div className="col-md-2 mb-2">
									<label htmlFor="number">Número</label>
									<input type="number" id="number" className="form-control form-control-sm" onChange={(e) => setData({...data, number: e.target.value})} value={data.number ?? ''} />
									{ errors.number &&
										<div className="invalid-feedback d-block">{ errors.number[0] }</div>
									}
								</div>
								<div className="col-md-5 mb-2">
									<label htmlFor="date">Fecha y hora</label>
									<input type="datetime-local" id="date" className="form-control form-control-sm" onChange={(e) => setData({...data, date: e.target.value})} value={data.date ? moment(data.date).format('YYYY-MM-DDTHH:mm') : ''} />
									{ errors.date &&
										<div className="invalid-feedback d-block">{ errors.date[0] }</div>
									}
								</div>
								<div className="col-md-5 mb-2">
									<label htmlFor="field_id">Campo</label>
									<EmpoweredSelector
										load={(input, callback) => loadFields(input, callback)}
										onChange={(value, text) => setData({...data, field_id: value, field_name: text})}
										timeout={500}
										value={data.field_id}
										label={data.field_name}
									/>
									{ errors.field_id &&
										<div className="invalid-feedback d-block">{ errors.field_id[0] }</div>
									}
								</div>
								<div className="col-md-4 mb-2">
									<label htmlFor="localteam_id">Equipo local</label>
									<EmpoweredSelector
										load={(input, callback) => loadTeams(input, callback)}
										onChange={(value, text) => setData({...data, localteam_id: value, localteam_name: text})}
										timeout={500}
										value={data.localteam_id}
										label={data.localteam_name}
									/>
									{ errors.localteam_id &&
										<div className="invalid-feedback d-block">{ errors.localteam_id[0] }</div>
									}
								</div>
								<div className="col-md-4 mb-2">
									<label htmlFor="awayteam_id">Equipo visitante</label>
									<EmpoweredSelector
										load={(input, callback) => loadTeams(input, callback)}
										onChange={(value, text) => setData({...data, awayteam_id: value, awayteam_name: text})}
										timeout={500}
										value={data.awayteam_id}
										label={data.awayteam_name}
									/>
									{ errors.awayteam_id &&
										<div className="invalid-feedback d-block">{ errors.awayteam_id[0] }</div>
									}
								</div>
								<div className="col-md-4 mb-2">
									<label htmlFor="scores">Puntuación</label>
									<div className="input-group">
										<input type="number" step="1" className="form-control form-control-sm" placeholder="Local" onChange={(e) => setData({...data, localteam_score: e.target.value})} value={data.localteam_score ?? ''} />
										<input type="number" step="1" className="form-control form-control-sm" placeholder="Visitante" onChange={(e) => setData({...data, awayteam_score: e.target.value})} value={data.awayteam_score ?? ''} />
									</div>
									{ errors.localteam_score &&
										<div className="invalid-feedback d-block">{ errors.localteam_score[0] }</div>
									}
									{ errors.awayteam_score &&
										<div className="invalid-feedback d-block">{ errors.awayteam_score[0] }</div>
									}
								</div>
								<div className="col-md-4 mb-2">
									<label>Ocultar en resultados</label>
									<div className="form-check form-switch">
										<input className="form-check-input" type="checkbox" checked={data.hide_on_results ?? false} onChange={(e) => setData({...data, hide_on_results: e.target.checked})} />
									</div>
									{ errors.hide_on_results &&
										<div className="invalid-feedback d-block">{ errors.hide_on_results[0] }</div>
									}
								</div>
							</form>
						</div>
						<div className="card-footer d-flex">
							{ (data.id && data.is_deletable) &&
								<button className="btn btn-sm btn-link text-secondary me-2" onClick={(e) => deleteMatch()}>Eliminar</button>
							}

							<div className="ms-auto">
								{ (data.id && !data.postponed_at && data.is_deletable) &&
									<button className="btn btn-sm btn-warning me-2" onClick={() => setPostponeModalOpened(true)}>Posponer</button>
								}
								{ !data.postponed_at &&
									<button className="btn btn-sm btn-danger text-white" onClick={(e) => saveData()}>Guardar</button>
								}
								{ data.postponed_at &&
									<span className="badge bg-warning">POSPUESTO PARA EL { moment(data.postponed_at).format('DD-MM-YYYY') }</span>
								}
							</div>
						</div>
					</div>
				</div>
			</div>

			{ (data.id && !data.postponed_at) &&
				<MatchFormPostponeModal 
					opened={postponeModalOpened} 
					closeCallback={() => setPostponeModalOpened(false)}
					id={data.id} 
				/>
			}
		</LoggedLayout>
	);
}