import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap/dist/js/bootstrap';

let axiosCancelToken = null;

export default function MatchFormPostponeModal(props) {
	const navigate = useNavigate();
	const modalObj = useRef(null);
	const modalRef = useRef(null);

	const [date, setDate] = useState('');
	const [reason, setReason] = useState('');
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		modalObj.current = new Modal(modalRef.current);

		const hiddenEvent = (e) => {
			console.log('se');
           	modalObj.current._element.removeEventListener('hidden.bs.modal', hiddenEvent);
			modalObj.current.dispose();
			props.closeCallback();
		}

		modalObj.current._element.addEventListener('hidden.bs.modal', hiddenEvent);

		return function cleanup() {
           	axiosCancelToken.cancel();
           	modalObj.current.hide();
        }
	}, []);


	useEffect(() => {
		if ( props.opened ) modalObj.current.show();
		else modalObj.current.hide();
	}, [props.opened]);

	const postPoneClickEvent = () => {
		setErrors({});
		
		axios.put('/api/matches/postpone/' + props.id, {
			date: date,
			reason: reason,
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			if ( response.data.status ) {
				toast.success('Se ha pospuesto el partido');
				navigate(-1);
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	return (
		<div className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-sm">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Posponer partido</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="mb-2">
							<label htmlFor="postpone_date">Nueva fecha</label>
							<input type="datetime-local" step="1" className="form-control form-control-sm" onChange={(e) => setDate(e.target.value)} value={date ? moment(date).format('YYYY-MM-DDTHH:mm') : ''} step="60" />
							{ errors.date &&
								<div className="invalid-feedback d-block">{ errors.date[0] }</div>
							}
						</div>
						<div className="mb-2">
							<label htmlFor="postpone_reason">Motivo</label>
							<textarea rows="5" className="form-control form-control-sm" onChange={(e) => setReason(e.target.value)} value={reason}></textarea>
							{ errors.reason &&
								<div className="invalid-feedback d-block">{ errors.reason[0] }</div>
							}
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-sm btn-danger text-white" onClick={() => postPoneClickEvent()}>Aceptar</button>
					</div>
				</div>
			</div>
		</div>
	);
}


