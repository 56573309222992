import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getUser, hasCapability } from 'helpers/user';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import LoggedLayout from "../layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";

let axiosCancelToken = null;

const PaginatorWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const Table = styled.table`
	@media (max-width: 768px) {
		td:not(.details):not(.noresults),
		th {
			&:nth-child(3),
			&:nth-child(4),
			&:last-of-type {
				display: none;
			}
		}
	}
`;

const Tr = styled.tr`

	&.postponed {
		background: var(--bs-red);
		color: white;

		&:hover {
			td {
				color: white !important;
			}
			
		}
	}

	td {
		font-weight: normal;
		user-select: none;
		cursor: pointer;

		&:first-of-type {
			white-space: nowrap;
			line-height: 100%;
		}

		&:last-of-type {
			background: ${props => props.opened ? 'var(--bs-dark)' : 'var(--bs-red)'};
			color: white !important;
			cursor: pointer;
			border-right: 0;
		}

		.modality {
			font-weight: bold;
			font-size: 11px;
		}
	}
`;

const TrDetails = styled.tr`
	display: ${props => props.opened ? 'table-row' : 'none'};

	& > td {
		border-bottom: 2px solid var(--bs-red);
		padding: 0;
	}

	&:hover > * {
		--bs-table-accent-bg: unset !important;
	}

	.save-scores-btn {
		padding: 2px 5px;
		height: auto;
		margin-top: -5px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
`;

const ScoresWrapper = styled.div`
	display: inline-flex;
	margin: 10px auto;
	width: 200px;

	input {
		width: 50%;

		text-align: center;
		border: 1px solid var(--bs-gray-400);

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
		  -webkit-appearance: none;
		  margin: 0;
		}
		-moz-appearance: textfield;
	}
`;

export default function ScorerRefererMatches() {
	const [searchParams] = useSearchParams();

	let [matches, setMatches] = useState([]);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('date');
	let [page, setPage] = useState(undefined);
	let [detailsOpened, setDetailsOpened] = useState([]);

	let type = searchParams.get('type');

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const loadData = async () => {
			await axios.get('api/scorer-referer-matches/list', {
				params: {
					type: type,
					page: page,
					sort: sortField,
					direction: sortDirection,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setMatches(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		loadData();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [page, sortDirection, sortField, type]);

	const sortTableClick = (field) => {
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const setScore = (what_team, match_id, score) => {
		let newMatches = [...matches.data]; 
    	let idx = newMatches.findIndex((el) => el.id === match_id);
    	newMatches[idx][what_team+'team_score'] = score ? parseInt(score) : '';
    	setMatches({...matches, data: newMatches});
	}

	const saveScores = (match_id) => {
    	let idx = matches.data.findIndex((el) => el.id === match_id);
    	if ( idx === -1 ) return;

		axios.post('api/scorer-referer-matches/set-score', {
			match_id: match_id,
			localteam_score: matches.data[idx].localteam_score,
			awayteam_score: matches.data[idx].awayteam_score,
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.success('Puntuación guardada');
	    	toggleDetails(match_id);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
	}

	const toggleDetails = (id) => {
		let newDetailsOpened = [...detailsOpened];

		if ( newDetailsOpened.includes(id) ) {
			let idx = newDetailsOpened.findIndex((el) => el === id);
			newDetailsOpened.splice(idx, 1);
		} else {
			newDetailsOpened.push(id);
		}

		setDetailsOpened(newDetailsOpened);
	}

	let user = getUser();

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>
					Partidos&nbsp;
					{ type == 'next' ? 'próximos' : '' }
					{ type == 'notpaid' ? 'sin pagar' : '' }
				</h1>
			</div>
			<div className="page-content">
				<div className="table-responsive">
					<Table className="table table-sm table-hover table-bordered table-sortable">
						<thead>
							<tr>
								<ThSortable style={{width: '130px'}} direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'field.name'} onClick={() => sortTableClick('field.name')}>Campo</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'localteam.name'} onClick={() => sortTableClick('localteam.name')}>Equipo Local</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'awayteam.name'} onClick={() => sortTableClick('awayteam.name')}>Equipo Visitante</ThSortable>
								<th style={{width: '100px'}}>Puntuación</th>
								<th style={{width: '10px'}}></th>
							</tr>
						</thead>
						<tbody>
							{ matches.data?.length > 0 &&
								matches.data?.map((el, idx) => {
									return ( 
										<React.Fragment key={idx}>
											<Tr className={el.postponed_at ? 'postponed' : ''} opened={detailsOpened.includes(el.id)} onClick={() => toggleDetails(el.id)}>
												<td>
													{ el.postponed_at &&
														<div className="mb-2 text-center fw-bold">POSPUESTO</div>
													}
													<div className="modality">{el.competition?.category?.modality?.name}</div>
													{moment(el.date).format('DD-MM-YYYY')} 
													<div><small>{moment(el.date).format('HH:mm')}</small></div>
													<span className="badge bg-light text-dark fw-normal">{el.code}</span>
												</td>
												<td>{el.field?.name}</td>
												<td>{el.localteam?.name}</td>
												<td>{el.awayteam?.name}</td>
												<td className="text-center">{el.localteam_score} - {el.awayteam_score}</td>
												<td>
													<i className={'bi bi-caret-' + (detailsOpened.includes(el.id) ? 'up' : 'down') + '-fill'}></i>
												</td>
											</Tr>
											
											<TrDetails opened={detailsOpened.includes(el.id)}>
												<td colSpan="100%" className="details">
													<div className="ps-2"><b>Equipo local:</b> {el.localteam?.name}</div>
													<div className="ps-2"><b>Equipo visitante:</b> {el.awayteam?.name}</div>

													{ hasCapability('set-scores') &&
														<div className="text-lg-center ps-2">
															<ScoresWrapper>
																<input type="number" value={el.localteam_score ?? ''} onChange={(e) => setScore('local', el.id, e.target.value)} /> 
																- 
																<input type="number" value={el.awayteam_score ?? ''} onChange={(e) => setScore('away', el.id, e.target.value)} />
															</ScoresWrapper>
															<button className="btn btn-sm btn-danger text-white save-scores-btn" onClick={(e) => saveScores(el.id)}><i className="bi bi-save"></i></button>
														</div>
													}
												</td>
											</TrDetails>
										</React.Fragment>
									);
								})
							}
							{ !matches.data?.length && <tr><td colSpan="100%" className="noresults">No hay datos disponibles</td></tr> }
						</tbody>
					</Table>	
				</div>

				<PaginatorWrapper>
					<Paginator
						min={1}
						current={matches?.current_page}
						max={matches?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</PaginatorWrapper>
			</div>
		</LoggedLayout>
	);
}


