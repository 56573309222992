import React, { useState, useRef } from 'react';
import { Navigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import { getUser, setUser } from 'helpers/user';
import { debug } from 'helpers/app';

export default function Login() {
	const [searchParams] = useSearchParams();

	// Login form
	const usernameRef = useRef();
	const passwordRef = useRef();

	// Request new password form
	const requestPasswordEmailRef = useRef();
	const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
	
	// Send new password form
	const newPasswordEmailRef = useRef(null);
	const newPasswordPasswordRef = useRef(null);
	const [newPasswordToken, setNewPasswordToken] = useState(searchParams.get('token'));
	const [newPasswordEmail] = useState(searchParams.get('email'));

	const login = (e) => {
		e.preventDefault();

		axios.get('sanctum/csrf-cookie').then(response => {
			axios.post('api/login', {
			    username: usernameRef.current.value,
			    password: passwordRef.current.value
		  	})
		  	.then((response) => {
		    	setUser(response.data.user, true);
		  	});	
		});
	};

	const requestNewPassword = (e) => {
		e.preventDefault();

		axios.get('sanctum/csrf-cookie').then(response => {

			axios.post('api/request-new-password', {
				email: requestPasswordEmailRef.current.value
			}).then(response => {
				if ( response.data.status ) {
					toast.success('Se ha enviado un email con instrucciones');
					requestPasswordEmailRef.current.value = "";
				} else {
					toast.error(response.data.error);
				}
			}).catch(error => {
				toast.error('Introduce un email válido');
			});

		});
	};

	const sendNewPassword = (e) => {
		e.preventDefault();

		axios.get('sanctum/csrf-cookie').then(response => {

			axios.post('api/reset-password', {
				token: newPasswordToken,
				email: newPasswordEmailRef.current.value,
				password: newPasswordPasswordRef.current.value,
			}).then(response => {
				if ( response.data.status ) {
					toast.success('Se ha cambiado la contraseña');
					setShowResetPasswordForm(false);
					setNewPasswordToken(false);
				} else {
					toast.error(response.data.error);
				}
			}).catch(error => {
				toast.error('Ha ocurrido un error');
			});

		});
	}

	if ( getUser() ) return <Navigate to="/" />;

	return (
		<div className="container">
			<div className="row justify-content-center mt-5">
				<div className="col-md-4">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title text-center mb-0">
								{ showResetPasswordForm || newPasswordToken ? 'Recuperar contraseña' : 'Acceder' }
							</h4>
						</div>
						<div className="card-body">
							{ (!showResetPasswordForm && !newPasswordToken) &&
								<form onSubmit={(e) => login(e)}>
									<div className="mb-3">
										{ debug ? 
											<select ref={usernameRef} defaultValue={debug ? 'admin' : ''} className="form-control form-control-sm">
												<option value="admin">Administrador</option>
												<option value="coordinador-beisbol">Coordinador beisbol</option>
												<option value="coordinador-sofbol">Coordinador sofbol</option>
												<option value="coordinador-arbitro">Coordinador arbitro</option>
												<option value="coordinador-anotador">Coordinador anotador</option>
												<option value="coordinador-monitor">Coordinador monitor</option>
												<option value="accounting">Contabilidad</option>
												<option value="referee">Árbitro</option>
												<option value="scorer">Anotador</option>
												<option value="monitor">Monitor</option>
												<option value="statistics">Estadísticas</option>
											</select>
											:
											<input type="text" ref={usernameRef} className="form-control form-control-sm" placeholder="Email" />
										}
									</div>
									<div className="mb-3">
										<input type="password" ref={passwordRef} defaultValue={debug ? '123456' : ''} className="form-control form-control-sm" placeholder="Contraseña" />
									</div>
									<div className="d-flex justify-content-between">
										<a className="btn btn-sm btn-link p-0 text-secondary" onClick={() => setShowResetPasswordForm(true)}>Recuperar contraseña</a>
										<button type="submit" className="btn btn-danger btn-sm text-white" onClick={(e) => login(e)}>Entrar</button>	
									</div>
								</form>
							}
							{ (showResetPasswordForm && !newPasswordToken) &&
								<form onSubmit={(e) => requestNewPassword(e)}>
									<div className="mb-3">
										<input type="email" ref={requestPasswordEmailRef} className="form-control form-control-sm" placeholder="Email" />
									</div>
									<div className="d-flex justify-content-between">
										<a className="btn btn-sm btn-link p-0 text-secondary" onClick={() => setShowResetPasswordForm(false)}>Volver</a>
										<button className="btn btn-danger btn-sm text-white" onClick={(e) => requestNewPassword(e)}>Enviar</button>	
									</div>
								</form>
							}
							{ newPasswordToken &&
								<form onSubmit={(e) => sendNewPassword(e)}>
									<div className="mb-3">
										<input type="email" required ref={newPasswordEmailRef} defaultValue={newPasswordEmail} className="form-control form-control-sm" placeholder="Email" />
									</div>
									<div className="mb-3">
										<input type="password" required minLength="8" ref={newPasswordPasswordRef} className="form-control form-control-sm" placeholder="Nueva contraseña" />
									</div>
									<div className="d-flex justify-content-end">
										<button className="btn btn-danger btn-sm text-white" type="submit">Guardar la nueva contraseña</button>	
									</div>
								</form>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}