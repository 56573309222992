import React, { useEffect, useState, useRef } from "react";
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import LoggedLayout from "../layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import EmpoweredSelector from "components/EmpoweredSelector";
import domtoimage from 'dom-to-image';

let axiosCancelToken = null;

const TableResponsive = styled.div`
	@media (min-width: 768px) {
		overflow: visible;
	}
`;

const Table = styled.table`
	background: white;
	
	tr[data-checked=true] {
		td {
			border-bottom: 1px solid lightgray;
		}
	}

	td.scorer {
		padding: 0;
		position: relative;

		.dropdown {
			height: 100%;
		}

		.empowered-selector {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			.empowered-selector_label {
				border: none;
				height: 100%;
				border-radius: 0;
			}
		}
	}
`;

const RefereeMonitor = styled.div`
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 5px 10px;
	border-radius: 10px;
	background: var(--bs-gray-100);
	margin: 5px;
	border: 1px solid var(--bs-gray-300);
	font-size: 12px;

	&:hover {
		cursor: pointer;

		&:after {
			content: "×";
			color: var(--bs-red);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(255,255,255,0.8);
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 30px;
		}
	}
`;

const PaginatorWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export default function RefereeScorerMonitorSelection(props) {
	let matchesRef = useRef(null);

	let [matches, setMatches] = useState([]);
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('date');
	let [page, setPage] = useState(undefined);
	let [dateFrom, setDateFrom] = useState('');
	let [dateTo, setDateTo] = useState('');
	let [showOnlyNotAssigned, setShowOnlyNotAssigned] = useState(true);
	let [limit, setLimit] = useState(50);
	let [selectedRows, setSelectedRows] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const loadData = async () => {
			await axios.get('api/referee-scorer-selection/list', {
				params: {
					page: page,
					sort: sortField,
					direction: sortDirection,
					only_not_assigned: showOnlyNotAssigned ? props.type : null,
					date_from: dateFrom,
					date_to: dateTo,
					limit: limit
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setMatches(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		loadData();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [page, sortDirection, sortField, showOnlyNotAssigned, dateFrom, dateTo, limit, props.type]);

	const loadUsers = (input, callback) => {
		axios.get('/api/users/list', {
			params: {
				search: input,
				no_paginate: true,
				role: props.type
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				let available = el.custom_fields?.available ?? false;
				return {
					value: el.id, 
					label:  <div>
								<div>{el.full_name}</div>
								<small className={'d-block text-' + (available ? 'success' : 'danger')}>{available ? 'Disponible' : 'No disponible'} esta semana</small>
							</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const sortTableClick = (field) => {
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const setScorer = (match_id, scorer_id) => {
		axios.put('/api/referee-scorer-selection/set-scorer', {
			match_id: match_id,
			scorer_id: scorer_id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			if ( response.data.status === 1 ) {
				// Update state
				let idx = matches.data.findIndex((el) => el.id === match_id);
				if ( idx !== -1 ) {
					let newMatches = [...matches.data];
					newMatches[idx].scorer = {
						id: response.data.scorer?.id,
						full_name: response.data.scorer?.full_name
					};
					setMatches({data: newMatches});
				}

				// Toast
				if ( response.data.scorer ) {
					toast.success('Anotador asignado');
				} else {
					toast.warning('Anotador quitado');
				}
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const addReferee = (match_id, referee_id) => {
		axios.put('/api/referee-scorer-selection/add-referee', {
			match_id: match_id,
			referee_id: referee_id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			if ( response.data.status === 1 ) {
				if ( response.data.referee ) {
					let idx = matches.data.findIndex((el) => el.id === match_id);
					if ( idx !== -1 ) {
						let newMatches = [...matches.data];
						newMatches[idx].referees.push({
							id: response.data.referee.id,
							full_name: response.data.referee.full_name,
						})
						setMatches({data: newMatches});
						toast.success('Árbitro asignado');
					}
				}
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const removeReferee = (match_id, referee_id) => {
		axios.post('/api/referee-scorer-selection/delete-referee', {
			match_id: match_id,
			referee_id: referee_id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			if ( response.data.status === 1 ) {
				let idx = matches.data.findIndex((el) => el.id === match_id);
				if ( idx !== -1 ) {
					let newMatches = [...matches.data];

					let refereeIdx = newMatches[idx].referees.findIndex((el) => el.id === referee_id);
					if ( refereeIdx !== -1 ) {
						let newReferees = [...newMatches[idx].referees];
						newReferees.splice(refereeIdx, 1);
						newMatches[idx].referees = newReferees;
						setMatches({data: newMatches});

						toast.warning('Árbitro quitado');
					}
				}
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const addMonitor = (match_id, monitor_id) => {
		axios.put('/api/referee-scorer-selection/add-monitor', {
			match_id: match_id,
			monitor_id: monitor_id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			if ( response.data.status === 1 ) {
				if ( response.data.monitor ) {
					let idx = matches.data.findIndex((el) => el.id === match_id);
					if ( idx !== -1 ) {
						let newMatches = [...matches.data];
						newMatches[idx].monitors.push({
							id: response.data.monitor.id,
							full_name: response.data.monitor.full_name,
						})
						setMatches({data: newMatches});
						toast.success('Monitor asignado');
					}
				}
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const removeMonitor = (match_id, monitor_id) => {
		axios.post('/api/referee-scorer-selection/delete-monitor', {
			match_id: match_id,
			monitor_id: monitor_id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			if ( response.data.status === 1 ) {
				let idx = matches.data.findIndex((el) => el.id === match_id);
				if ( idx !== -1 ) {
					let newMatches = [...matches.data];

					let refereeIdx = newMatches[idx].monitors.findIndex((el) => el.id === monitor_id);
					if ( refereeIdx !== -1 ) {
						let newMonitors = [...newMatches[idx].monitors];
						newMonitors.splice(refereeIdx, 1);
						newMatches[idx].monitors = newMonitors;
						setMatches({data: newMatches});

						toast.warning('Monitor quitado');
					}
				}
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const toggleAllCheckboxes = () => {
		// Set all selected
		if ( selectedRows.length < matches.data?.length ) {
			let newSelectedRows = [];
			matches.data?.forEach((el) => newSelectedRows.push(el.id));
			setSelectedRows(newSelectedRows);
		} else { // Deselect all
			setSelectedRows([]);
		}
	}

	const toggleSelectedRow = (id) => {
		let newSelectedRows = [...selectedRows];
		if ( !selectedRows.includes(id) ) newSelectedRows.push(id);
		else {
			let idx = selectedRows.findIndex((el) => el === id);
			if ( idx !== -1 ) newSelectedRows.splice(idx, 1);
		}
		setSelectedRows(newSelectedRows);
	}

	const generateImage = () => {

		if ( !selectedRows.length ) return;

		const hide = (hideEmpoweredSelector = false) => {
			matchesRef.current.querySelectorAll('tr[data-checked=false]').forEach((el) => el.style.display = 'none');
			if ( hideEmpoweredSelector ) matchesRef.current.querySelectorAll('tr[data-checked=true] .empowered-selector').forEach((el) => el.style.display = 'none');
		}

		const show = () => {
			matchesRef.current.querySelectorAll('tr[data-checked=false]').forEach((el) => el.style.display = 'table-row');
			matchesRef.current.querySelectorAll('tr[data-checked=true] .empowered-selector').forEach((el) => el.style.display = 'inherit');
		}
		
		hide(props.type === 'referee' || props.type === 'monitor');
		
		domtoimage.toPng(matchesRef.current)
				    .then(function (dataUrl) {
				        
				        let a = document.createElement('a');
					    a.href = dataUrl;
					    a.download = 'captura.png';
					    a.click();
					    a.remove();

						show();
				    })
				    .catch(function (error) {
						show();

				        toast.error('Error al obtener la imagen');
				        console.error(error);
				    });
	}
 
	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>
					Selección de&nbsp;
					{ props.type === 'referee' ? 'árbitros' : '' }
					{ props.type === 'scorer' ? 'anotadores' : '' }
					{ props.type === 'monitor' ? 'monitores' : '' }
				</h1>
			</div>
			<div className="page-content">
				<div className="row">
					<div className="col-md-3">
						<button className="btn btn-sm btn-warning" onClick={() => generateImage()}>Sacar foto</button>
					</div>
					<div className="col-md-6">
						<div className="input-group input-group-sm">
							<input type="date" className="form-control form-control-sm" onChange={(e) => setDateFrom(e.target.value)} value={dateFrom} />
							<input type="date" className="form-control form-control-sm" onChange={(e) => setDateTo(e.target.value)} value={dateTo} />
						</div>	
					</div>
					<div className="col-md-3">
						<select value={limit} onChange={(e) => setLimit(e.target.value)} className="form-control form-control-sm">
							<option value="">- Límite -</option>
							{ [50, 100, 500, 1000].map((el) => {
								return <option key={el} value={el}>{el}</option>;
							}) }
						</select>


					</div>
					<div className="col-md-12 mt-3 d-flex justify-content-end">
						<label className="d-block">
							<div className="form-check form-switch">
								<input className="form-check-input" type="checkbox" onChange={(e) => setShowOnlyNotAssigned(e.target.checked)} checked={showOnlyNotAssigned} id="showOnlyeNotAssigned" />
								<label className="form-check-label" htmlFor="showOnlyeNotAssigned">Mostrar partidos sin asignar </label>
							</div>
						</label>
					</div>
				</div>

				<React.Fragment>
					<TableResponsive className="table-responsive">
						<Table className="table table-sm table-bordered table-sortable">
							<thead>
								<tr>
									<th style={{width: '20px'}}><input type="checkbox" onChange={(e) => toggleAllCheckboxes()} checked={selectedRows.length === matches.data?.length} /></th>
									<th style={{width: '20px'}}>#</th>
									<ThSortable style={{width: '130px'}} direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
									<ThSortable style={{width: '100px'}} direction={sortDirection} active={sortField === 'field.name'} onClick={() => sortTableClick('field.name')}>Campo</ThSortable>
									<th style={{width: '200px'}}>Equipos</th>
									{ props.type === 'referee' &&
										<th>Árbitros</th>
									}
									{ props.type === 'scorer' &&
										<th>Anotador</th>
									}
								</tr>
							</thead>
							<tbody ref={matchesRef}>
								{ matches.data?.length > 0 &&
									matches.data?.map((el, idx) => {
										return ( 
											<tr key={idx} data-checked={selectedRows.includes(el.id) ? "true" : "false"}>
												<td style={{verticalAlign: 'middle'}}><input type="checkbox" onChange={(e) => toggleSelectedRow(el.id)} checked={selectedRows.includes(el.id)} /></td>
												<td><span className="badge bg-light text-dark fw-normal">{el.code}</span></td>
												<td>
													{moment(el.date).format('DD-MM-YYYY')} <small>{moment(el.date).format('HH:mm')}</small>
													{ el.postponed_at &&
														<div className="text-center">
															<span className="postponed-badge badge bg-secondary">Pospuesto</span>
														</div>
													}
												</td>
												<td>{el.field?.name}</td>
												<td>
													<div>Local: {el.localteam?.name}</div>
													<div>Visitante: {el.awayteam?.name}</div>
												</td>
												{ props.type === 'referee' &&
													<td>
														<div className="d-flex">
															<div className="ms-auto w-50">
																<EmpoweredSelector
																	load={(input, callback) => loadUsers(input, callback)}
																	onChange={(id) => addReferee(el.id, id)}
																	timeout={500}
																	placeholder={'Seleccionar'}
																	except={el.referees.map((elR) => elR.id)}
																/>
															</div>
														</div>
														<div className="text-center">
															{ (el.referees && el.referees.length) ? el.referees.map((elR, idxR) => {
																return <RefereeMonitor key={idxR} onClick={() => removeReferee(el.id, elR.id)}>{ elR.full_name }</RefereeMonitor>;
															}) : '' }
														</div>
													</td>
												}
												{ props.type === 'monitor' &&
													<td>
														<div className="d-flex">
															<div className="ms-auto w-50">
																<EmpoweredSelector
																	load={(input, callback) => loadUsers(input, callback)}
																	onChange={(id) => addMonitor(el.id, id)}
																	timeout={500}
																	placeholder={'Seleccionar'}
																	except={el.monitors.map((elR) => elR.id)}
																/>
															</div>
														</div>
														<div className="text-center">
															{ (el.monitors && el.monitors.length) ? el.monitors.map((elR, idxR) => {
																return <RefereeMonitor key={idxR} onClick={() => removeMonitor(el.id, elR.id)}>{ elR.full_name }</RefereeMonitor>;
															}) : '' }
														</div>
													</td>
												}
												{ props.type === 'scorer' &&
													<td className="scorer">
														<EmpoweredSelector
															load={(input, callback) => loadUsers(input, callback)}
															onChange={(id) => setScorer(el.id, id)}
															timeout={500}
															placeholder={'Seleccionar'}
															value={el.scorer?.id}
															label={el.scorer?.full_name}
															except={[el.scorer?.id]}
														/>
													</td>
												}
											</tr>
										);
									})
								}
								{ !matches.data?.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }
							</tbody>
						</Table>	
					</TableResponsive>

					<PaginatorWrapper>
						<Paginator
							min={1}
							current={matches?.current_page}
							max={matches?.last_page}
							changeCallback={(page) => setPage(page)}
						/>
					</PaginatorWrapper>
				</React.Fragment>
				
			</div>
		</LoggedLayout>
	);
}


