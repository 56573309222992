import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import { getUser, setUser, hasCapability } from 'helpers/user';
import { debug } from 'helpers/app';
import LogoComunidadMadrid from 'assets/img/logo_comunidad_madrid.png';
import LogoCiudadMadrid from 'assets/img/logo_ciudad_madrid.png';
import axios from 'axios';

const LayoutWrapper = styled.div`
	.container {
		max-width: 1024px;
	}
`;

const Navbar = styled.nav`
	background: var(--bs-red);
	box-shadow: 0px 2px 10px rgb(1 41 112 / 10%);
	z-index: 21;

	.navbar-brand {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		font-family: 'Open Sans', sans-serif;
		color: white !important;

		.badge {
			font-weight: lighter;
			font-size: 10px;
		}

		@media (min-width: 993px) {
			width: 250px;
		}
	}

	.btn-navbar-toggler {
		display: inline;
		box-shadow: none !important;
	}

	.navbar-nav > .nav-item.dropdown {
		& > .nav-link {
			color: white !important;
		}

		ul > li > a {
			&:active {
				background: var(--bs-red) !important;
			}
		}
	}
`;

const Sidebar = styled.aside`
	--top: 45px;

	position: fixed;
	top: var(--top);
	left: 0;
	z-index: 20;
	width: 250px;
	height: calc(100% - var(--top));
	background: white;
	padding: 15px;
    box-shadow: 0px 0px 10px rgb(1 41 112 / 10%);
	transition: all 0.5s;
	overflow-y: auto;

	ul {
		list-style: none;
		padding-left: 0;

		li {
			margin: 10px 0;

			a {
				display: flex;
				text-decoration: none;
				color: var(--bs-body-color);
				padding: 10px 15px;
				transition: all 0.3s;
				font-weight: 500;
				font-size: 16px;

				i {
					width: 30px;
				}

				&.active {
					border-radius: 5px;
					background: var(--bs-danger);
					color: white;
				}

				&:hover:not(.active) {
					border-radius: 5px;
					background: #fff7f9;
					color: var(--bs-red);
				}
			}
		}
	}

	&:not(.visible) {
		left: -100%;
	}
`;


const Main = styled.main`
	margin-top: 60px;

	.page-title {
		display: flex;
		padding: 20px;
		align-items: center;
		border-bottom: 1px solid var(--bs-gray-400);
		
		h1 {
			font-size: 35px;
			line-height: 35px;
			margin: 0;
		}
	}

	.page-content {
		padding: 20px 30px;

		@media (max-width: 992px) {
			& {
				padding: 10px 0;
			}
		}
	}

	@media (max-width: 992px) {
    	& {
    		margin-left: 0;
    	}
    }
`;


const Footer = styled.footer`
	user-select: none;

	.col {
		padding-top: 10px;
		
		&:nth-child(1),
		&:nth-child(3) {
			img {
				width: 50px;
			}
		}

		&:nth-child(2) {
			font-size: 13px;
			line-height: 13px;
			font-weight: 700;

			small {
				display: block;
				font-size: 11px;
				color: gray;
				font-weight: normal;
			}
		}
	}

	.line-separator {
		padding: 0 20px;
		border-top: 1px solid var(--bs-gray-400);
	}
`;

export default function LoggedLayout(props) {
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const logout = (e) => {
    	e.preventDefault();

    	axios.post('api/logout', {}).then((response) => {
	    	setUser(null, true);
	  	});	    	
    }

	return (
		<React.Fragment>
			<LayoutWrapper>
				<Navbar className="navbar navbar-expand-lg navbar-dark fixed-top">
					<div className="container">
						<button className="btn btn-navbar-toggler" onClick={() => setSidebarVisible(!sidebarVisible)}>
							<span className="navbar-toggler-icon"></span>
						</button>
						<NavLink className="navbar-brand" to="/">
							FMBS
							{ debug && 
								<span className="badge bg-white text-dark ms-2">debug</span>
							}
						</NavLink>
						<div className="collapse navbar-collapse">
							<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
								<li className="nav-item dropdown">
									<a href="/" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown">
										{ getUser()?.name }
									</a>
									<ul className="dropdown-menu dropdown-menu-end">
										<li><a className="dropdown-item" href="/logout" onClick={(e) => logout(e)}>Cerrar sesión</a></li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</Navbar>

				<Sidebar className={sidebarVisible ? 'visible' : ''}>
					<div className="d-lg-none text-center mt-2">
						Hola, { getUser()?.name }
					</div>

					<ul>
						<li>
							<NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Dashboard</NavLink>
						</li>

						{ hasCapability('users') &&
							<li>
								<NavLink to="/users" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Usuarios</NavLink>
							</li>
						}	

						{ hasCapability('users-referee') &&
							<li>
								<NavLink to="/users" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Arbitros</NavLink>
							</li>
						}	

						{ hasCapability('users-scorer') &&
							<li>
								<NavLink to="/users" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Anotadores</NavLink>
							</li>
						}	

						{ hasCapability('modalities') &&
							<li>
								<NavLink to="/modalities" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Modalidades</NavLink>
							</li>
						}		

						{ hasCapability('categories') &&
							<li>
								<NavLink to="/categories" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Categorías</NavLink>
							</li>
						}			

						{ hasCapability('fields') &&
							<li>
								<NavLink to="/fields" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Campos</NavLink>
							</li>
						}			

						{ hasCapability('teams') &&
							<li>
								<NavLink to="/teams" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Equipos</NavLink>
							</li>
						}					

						{ (hasCapability('competitions') || hasCapability('competitions-statistics-upload')) &&
							<li>
								<NavLink to="/competitions" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Competiciones</NavLink>
							</li>
						}									

						{ hasCapability('matches') &&
							<li>
								<NavLink to="/matches" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Partidos</NavLink>
							</li>
						}						

						{ hasCapability('matches-referee') &&
							<li>
								<NavLink to="/referee-selection" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Selección de árbitros</NavLink>
							</li>
						}									

						{ hasCapability('matches-scorer') &&
							<li>
								<NavLink to="/scorer-selection" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Selección de anotadores</NavLink>
							</li>
						}	

						{ hasCapability('matches-monitor') &&
							<li>
								<NavLink to="/monitor-selection" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Selección de monitores</NavLink>
							</li>
						}							

						{ hasCapability('accounting') &&
							<li>
								<NavLink to="/accounting" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Contabilidad</NavLink>
							</li>
						}							

						{ hasCapability('receipts') &&
							<li>
								<NavLink to="/receipts" className={({ isActive }) => isActive ? 'active' : ''}>
									<i className="bi"></i> 
									{ hasCapability('scorer-referer-matches') ? 'Pagados' : 'Recibos' }
								</NavLink>
							</li>
						}							

						{ hasCapability('scorer-referer-matches') &&
							<li>
								<NavLink to="/scorer-referer-matches?type=next" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Próximos partidos</NavLink>
							</li>
						}							

						{ hasCapability('scorer-referer-matches') &&
							<li>
								<NavLink to="/scorer-referer-matches?type=notpaid" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Partidos no pagados</NavLink>
							</li>
						}						

						{ hasCapability('generate-scripts') &&
							<li>
								<NavLink to="/generate-scripts" className={({ isActive }) => isActive ? 'active' : ''}><i className="bi"></i> Shortcodes</NavLink>
							</li>
						}						

						<li className="d-lg-none">
							<a href="/logout" onClick={(e) => logout(e)}><i className="bi bi-box-arrow-right"></i> Cerrar sesión</a>
						</li>
					</ul>
				</Sidebar>

				<Main>
					<div className="container">
						{ props.children }
					</div>
				</Main>

				<Footer>
					<div className="container">
						<div className="line-separator"></div>
						<div className="row">
							<div className="col">
								<img src={LogoComunidadMadrid} alt="logo-comunidad-madrid" />
							</div>
							<div className="col text-center">
								FEDERACIÓN MADRILEÑA DE BÉISBOL Y SÓFTBOL
								<small>Avda. Sala de los Infantes, 1 - 28034 Madrid</small>
							</div>
							<div className="col text-end">
								<img src={LogoCiudadMadrid} alt="logo-ciudad-madrid" />
							</div>
						</div>
					</div>
				</Footer>
			</LayoutWrapper>
		</React.Fragment>
	);
}