import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { hasCapability } from 'helpers/user';

let axiosCancelToken = null;

const TableDetails = styled.table`
	width: 100%;
	font-size: 13px;

	thead {

		th {
			font-weight: normal;
			border-bottom: 1px solid var(--bs-gray-300);
			padding: 5px;

			&:nth-child(1) {
				width: 200px;
			}

			&:nth-child(4) {
				width: 100px;
			}

			&:nth-child(5) {
				width: 150px;
				text-align: right;
			}

			&:nth-child(6) {
				width: 20px;
			}
		}
	}

	tbody {

		tr {
			border-bottom: 1px solid var(--bs-gray-300);

			td {
				padding: 5px;

				&:first-of-type {
					div {
						white-space: nowrap;
					}
				}

				&:nth-child(5) {
					text-align: right;

					small {
						font-size: 9px;
					}
				}

				input[type=checkbox] {
					width: 15px;
					height: 15px;
					cursor: pointer;
				}
			}
		}
	}
`;

export default function ReceiptDetails(props) {
	let [data, setData] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const getData = async () => {
			return await axios.get('api/receipts/get/' + props.receipt.id, {
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setData(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getData();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [props.receipt.id]);


	const generatePDF = () => {
		axios.get('api/receipts/generate-pdf/' + props.receipt.id, {
			responseType: 'blob',
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	     	let url = window.URL.createObjectURL(response.data);
	      	let a = document.createElement('a');
			a.href = url;
			a.download = 'recibo_' + props.receipt.full_number.replace('/', '-') + '.pdf';
			a.click();
			a.remove();
			setTimeout(() => window.URL.revokeObjectURL(url), 100);
	    }).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const togglePaid = () => {
		axios.put('api/accounting/toggle-paid-receipt/' + props.receipt.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			props.reloadParent();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const deleteReceipt = () => {
		let c = window.confirm('¿Seguro que quieres eliminar este recibo?');
		if ( !c ) return;

		axios.delete('api/accounting/delete-receipt/' + props.receipt.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			props.reloadParent();
			toast.warning('Recibo eliminado');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	return (
		<TableDetails>
			{ (data.matches && data.matches.length > 0) &&
				<React.Fragment>
					<thead>
						<tr>
							<th>Fecha</th>
							<th>Competición</th>
							<th>Equipos</th>
							<th>Campo</th>
							<th>Importe</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{ data.matches.map((el, idx) => {
							return (
								<tr key={idx}>
									<td>
										<div>{moment(el.date).format('DD-MM-YYYY')}</div>
										<div>a las {moment(el.date).format('HH:mm')}</div>
									</td>
									<td>
										{el.competition.name}
										<div><small><i>{el.competition.category?.modality?.name} {el.competition.category?.name}</i></small></div>
									</td>
									<td>
										{ el.localteam?.abbreviation } - { el.awayteam?.abbreviation }
									</td>
									<td>{el.field?.name}</td>
									<td>
										<div>{el.pivot.total.toFixed(2)} €</div>
										{ el.pivot.complement > 0 &&
											<div><small>(complemento {el.pivot.complement}€ aplicado)</small></div>
										}
									</td>
								</tr>
							);
						}) }
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="100%" className="p-2 text-end">
								{ props.receipt.irpf_amount > 0 &&
									<React.Fragment>
										<div className="fw-bold mb-2">IMPORTE: {parseFloat(props.receipt.total_without_irpf).toFixed(2)}€</div>
										<div className="fw-bold mb-2">RETENCIÓN IRPF {props.receipt.irpf_percent}%: -{parseFloat(props.receipt.irpf_amount).toFixed(2)}€</div>
									</React.Fragment>
								}
								<div className="fw-bold mb-2">TOTAL: {props.receipt.total.toFixed(2)}€</div>
								<div className="d-flex justify-content-between">
									{ hasCapability('accounting') &&
										<React.Fragment>
											<button style={{visibility: !props.receipt.is_deletable ? 'hidden' : ''}} className="btn btn-sm btn-link text-secondary" onClick={() => deleteReceipt()}>Eliminar</button>									
											<button className={'btn btn-sm btn-sm btn-' + (props.receipt.paid_at ? 'warning' : 'success')} onClick={() => togglePaid()}>Marcar como {props.receipt.paid_at ? 'no pagado' : 'pagado'}</button>
										</React.Fragment>
									}
									<button className={"btn btn-sm btn-danger text-white " + (hasCapability('accounting') ? '' : 'ms-auto')} onClick={() => generatePDF()}>Descargar PDF</button>
								</div>
							</td>
						</tr>
					</tfoot>
				</React.Fragment>
			}
			{ (!data.matches || data.matches.length === 0) &&
				<tbody>
					<tr>
						<td colSpan="100%" className="text-center">cargando...</td>
					</tr>
				</tbody>
			}
		</TableDetails>
	);
}