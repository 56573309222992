import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios';
import LoggedLayout from "../layouts/LoggedLayout";
import Paginator from 'components/Paginator';

let axiosCancelToken = null;

const PaginatorWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

let searchTimeout = null;

export default function Modalities() {
	let [fields, setFields] = useState([]);
	let [search, setSearch] = useState("");
	let [page, setPage] = useState(undefined);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const loadData = async () => {
			await axios.get('api/fields/list', {
				params: {
					page: page,
					search: search
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setFields(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		loadData();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [page, search]);

	const setSearchTimeout = (value) => {
		clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
		}, 500);
	}

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>Campos</h1>
				<NavLink to="/fields/new" className="btn btn-sm btn-danger text-white ms-auto">Añadir <i className="bi bi-plus"></i></NavLink>
			</div>
			<div className="page-content">
				<div className="row mb-3">
					<div className="col-md-4">
						
					</div>
					<div className="col-md-4">
						
					</div>
					<div className="col-md-4">
						<input type="text" className="form-control form-control-sm" placeholder="Buscar..." onChange={(e) => setSearchTimeout(e.target.value)} />
					</div>
				</div>
				<div className="table-responsive">
					<table className="table table-sm table-hover table-bordered table-sortable">
						<thead>
							<tr>
								<th style={{width: '20px'}}>#</th>
								<th>Campo</th>
								<th style={{width: '120px'}}>Latitud</th>
								<th style={{width: '120px'}}>Longitud</th>
								<th style={{width: '20px'}}></th>
							</tr>
						</thead>
						<tbody>
							{ fields.data?.length > 0 &&
								fields.data?.map((el, idx) => {
									return ( 
										<React.Fragment key={idx}>
											<tr>
												<td>{el.id}</td>
												<td>{el.name}</td>
												<td>{el.latitude}</td>
												<td>{el.longitude}</td>
												<td>
													<NavLink to={'/fields/edit/' + el.id} className="text-danger"><i className="bi bi-pencil"></i></NavLink>
												</td>
											</tr>
										</React.Fragment>
									);
								})
							}
							{ !fields.data?.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }
						</tbody>
					</table>	
				</div>

				<PaginatorWrapper>
					<Paginator
						min={1}
						current={fields?.current_page}
						max={fields?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</PaginatorWrapper>
			</div>
		</LoggedLayout>
	);
}


