import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios';
import { hasCapability } from 'helpers/user';
import LoggedLayout from "../layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import EmpoweredSelector from "components/EmpoweredSelector";
import ThSortable from "components/ThSortable";

let axiosCancelToken = null;

const PaginatorWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

let searchTimeout = null;

export default function Users() {
	let [users, setUsers] = useState([]);
	let [search, setSearch] = useState("");
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('name');
	let [roles, _setRoles] = useState([]);
	let rolesRef = useRef(roles);
	let setRoles = (data) => {
		rolesRef.current = data;
		_setRoles(data);
	}
	let [role, setRole] = useState({
		key: '',
		value: ''
	});
	let [page, setPage] = useState(undefined);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const loadData = async () => {
			await axios.get('api/users/list', {
				params: {
					page: page,
					search: search,
					sort: sortField,
					direction: sortDirection,
					role: role.key ?? '',
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setUsers(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		loadData();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [page, search, sortDirection, sortField, role]);

	useEffect(() => {
		const loadRoles = async () => {
	        // Get coordinators
			await axios.get('/api/users/roles', {
				params: {
					type: 'direct'
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				let formatted = response.data.map((el) => {
					return {
						value: el.key, 
						label: el.name,
						header: el.header ?? false
					};
				});
				setRoles(formatted);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		loadRoles();
	}, []);

	const setSearchTimeout = (value) => {
		clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
		}, 500);
	}

	const sortTableClick = (field) => {
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	return (
		<LoggedLayout>
			<div className="page-title">
				<h1>Usuarios</h1>
				<NavLink to="/users/new" className="btn btn-sm btn-danger text-white ms-auto">Añadir <i className="bi bi-plus"></i></NavLink>
			</div>
			<div className="page-content">
				<div className="row mb-3">
					<div className="col-md-4">
						
					</div>
					<div className="col-md-4">
						{ hasCapability('users') &&
							<EmpoweredSelector
								load={(input, callback) => {
									let inputSanitized = input.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
									let filteredRoles = rolesRef.current.filter((el) => {
										if ( el.header ) return true;
										let elSanitized = el.label.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
										if ( elSanitized.indexOf(inputSanitized) !== -1 ) return true;
										return false;
									});
									callback(filteredRoles); 
								}}
								onChange={(value, text) => setRole({key: value, name: text})}
								value={role.key}
								label={role.name}
								placeholder="Rol"
								timeout={500}
							/>
						}
					</div>
					<div className="col-md-4">
						<input type="text" className="form-control form-control-sm" placeholder="Buscar..." onChange={(e) => setSearchTimeout(e.target.value)} />
					</div>
				</div>

				<div className="table-responsive">
					<table className="table table-sm table-hover table-bordered table-sortable">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
								{ hasCapability('users') &&
									<ThSortable direction={sortDirection} active={sortField === 'role'} onClick={() => sortTableClick('role')}>Rol</ThSortable>
								}
								<ThSortable direction={sortDirection} active={sortField === 'vatnumber'} onClick={() => sortTableClick('vatnumber')}>DNI</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'email'} onClick={() => sortTableClick('email')}>Email</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'phone'} onClick={() => sortTableClick('phone')}>Teléfono</ThSortable>
								<th style={{width: '20px'}}></th>
							</tr>
						</thead>
						<tbody>
							{ users.data?.length > 0 &&
								users.data?.map((el, idx) => {
									return ( 
										<tr key={idx}>
											<td>{el.name} {el.lastname1} {el.lastname2}</td>
											{ hasCapability('users') &&
												<td>{ roles.find((rEl) => rEl.value === el.role)?.label }</td>
											}
											<td>{el.vatnumber}</td>
											<td>{el.email}</td>
											<td>{el.phone}</td>
											<td>
												<NavLink to={'/users/edit/' + el.id} className="text-danger"><i className="bi bi-pencil"></i></NavLink>
											</td>
										</tr>
									);
								})
							}
							{ !users.data?.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }
						</tbody>
					</table>	
				</div>

				<PaginatorWrapper>
					<Paginator
						min={1}
						current={users?.current_page}
						max={users?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</PaginatorWrapper>
			</div>
		</LoggedLayout>
	);
}


